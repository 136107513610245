import React from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

const Downloaded_resumes = () => {
    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
              <div class="section-box jobpost-sec">
                     <div class="container">
                        <div class="head-border mb-30 d-flex justify-content-between align-items-center">
                           <h5>Downloaded Resumes</h5>
                          
                        </div>

                        <div class="resumesearch d-flex">
                           <div class="">
                               <label class="form-label">Filter By Date Range</label>
                               <input class="form-control" type="date" />
                           </div>
                           <div>
                               <a class="btn btn-default ml-10" href="#">Search</a>
                           </div>
                           <div>
                               <a class="btn btn-default ml-10" href="#">View All</a>
                           </div>

                        </div>
                        <div class="table-responsive resume_table">
                           <table class="table table-striped">
                              <thead>
                                 <tr role="row">
                                    <th>S.No</th>
<th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Password</th>
                                    <th>Resume</th>
<th>Downloaded Date</th>
                                    
                                 </tr>
                              </thead>
                              <tbody><tr role="row" class="odd">
                                    <td>1</td>
<td>Vipul Reddy</td>
                                    <td>reddyvipul9797@gmail.com</td>
                                    <td>9010965224</td>
                                    <td>PD8AT7N8</td>
                                    <td>
                                       
                                       
                                       <a href="#" class="roundtab">View</a>
                                    </td>
                                    <td>20-09-22</td>
                                 </tr>
                                 <tr role="row" class="odd">
                                    <td>2</td>
<td>Vipul Reddy</td>
                                    <td>reddyvipul9797@gmail.com</td>
                                    <td>9010965224</td>
                                    <td>PD8AT7N8</td>
                                    <td>
                                       
                                       
                                       <a href="#" class="roundtab">View</a>
                                    </td>
                                    <td>20-09-22</td>
                                 </tr>
                                 
                                 <tr role="row" class="odd">
                                    <td>3</td>
<td>Vipul Reddy</td>
                                    <td>reddyvipul9797@gmail.com</td>
                                    <td>9010965224</td>
                                    <td>PD8AT7N8</td>
                                    <td>
                                       
                                       
                                       <a href="#" class="roundtab">View</a>
                                    </td>
                                    <td>20-09-22</td>
                                 </tr><tr role="row" class="odd">
                                    <td>4</td>
<td>Vipul Reddy</td>
                                    <td>reddyvipul9797@gmail.com</td>
                                    <td>9010965224</td>
                                    <td>PD8AT7N8</td>
                                    <td>
                                       
                                       
                                       <a href="#" class="roundtab">View</a>
                                    </td>
                                    <td>20-09-22</td>
                                 </tr>
                                 
                                 
                                 
                                 
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Downloaded_resumes;
import React, { useState } from "react";
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";


//import { MDBSelect } from 'mdb-react-ui-kit';




const Add_post_jobs = () => {
    const [field, setField] = useState([]);
    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                <div className="section-box jobpost-sec">
                    <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                            <h5>Post Job</h5>
                            <a href="#" data-bs-toggle="modal" data-bs-target="#Modalgroup" className="btn btn-default btn-small ml-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>
                            Back To Job Listing</a>
                        </div>
                        <div className="grayborderbox mb-30">
                            <div className="box-padding">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10"> 
                                            <label className="font-sm color-text-mutted mb-5">Job Title</label>
                                            <input className="form-control" type="text" placeholder="Enter Job Title" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10 selectdesign">
                                            <label className="font-sm color-text-mutted mb-5">Status For Join</label>
                                            <select className="form-control no-pad website-input" name="jobtype" id="jobtype">
                                                <option value="">Select</option>
                                                <option value="Immediate joiners">Immediate joiners</option>
                                                <option value="Join in a Month">Join in a Month</option>
                                                <option value="Walk in interview">Walk in interview</option>
                                                <option value="work from home">Work from home</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10"> 
                                            <label className="font-sm color-text-mutted mb-5">Number of Vacancies</label>
                                            <input className="form-control" type="text" placeholder="Enter Number of Vacancies" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10"> 
                                            <label className="font-sm color-text-mutted mb-5">Required Skills:</label>
                                            <input className="form-control" type="text" placeholder="Enter Skills" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10 selectdesign">
                                            <label className="font-sm color-text-mutted mb-5">Job Location</label>
                                            <select id="location_id" name="location_id" className="form-input form-control" data-placeholder="Select Job Location" required="" data-select2-id="location_id" tabIndex="-1" aria-hidden="true">
                                                <option data-select2-id="8"></option>
                                                <option value="6">Ahmedabad</option>
                                                <option value="1">Andhra Pradesh</option>
                                                <option value="2">Chennai</option>
                                                <option value="4">Delhi</option>
                                                <option value="3">Hyderabad</option>
                                                <option value="5">Indore</option>
                                                <option value="9">Jaipur</option>
                                                <option value="7">Kolkata</option>
                                                <option value="8">Lucknow</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10"> 
                                            <label className="font-sm color-text-mutted mb-5">Zipcode</label>
                                            <input className="form-control" type="text" placeholder="Enter Skills" />
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10 selectdesign">
                                            <label className="font-sm color-text-mutted mb-5">Salary</label>
                                            <select name="job_attrs[]" className="form-input form-control" data-placeholder="Select Salary" data-select2-id="9" tabIndex="-1" aria-hidden="true">
                                                <option data-select2-id="11"></option>
                                                <option value="2_17">0-3 Lakhs</option>
                                                <option value="2_18">3-6 Lakhs</option>
                                                <option value="2_19">6-10 Lakhs</option>
                                                <option value="2_20">10-15 Lakhs</option>
                                                <option value="2_21">15-25 Lakhs</option>
                                                <option value="2_22">25-50 Lakhs</option>
                                                <option value="2_23">50-75 Lakhs</option>
                                                <option value="2_24">75-100 Lakhs</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10 selectdesign">
                                            <label className="font-sm color-text-mutted mb-5">Job Industry</label>
                                            <select id="job_industry" name="job_industry" className="form-input form-control" data-placeholder="Select Job Industry" required="" data-select2-id="job_industry" tabIndex="-1" aria-hidden="true">
                                                <option data-select2-id="13"></option>
                                                <option value="792">Agriculture And Allied Industries</option>
                                                <option value="793">Automobiles</option>
                                                <option value="794">Auto Components</option>
                                                <option value="795">Aviation Industry</option>
                                                <option value="796">Banking Industry</option>
                                                <option value="797">Cement Industry</option>
                                                <option value="798">Consumer Durable Industry</option>
                                                <option value="799">E Commerce</option>
                                                <option value="800">Education And Training</option>
                                                <option value="801">Engineering And Capital Goods</option>
                                                <option value="802">Financial Services Industry</option>
                                                <option value="803">FMCG</option>
                                                <option value="804">Gems And Jewellery</option>
                                                <option value="805">Health Care Industry</option>
                                                <option value="806">Infrastructure</option>
                                                <option value="807">Insurance Industry</option>
                                                <option value="808">IT / Software Industry</option>
                                                <option value="809">Manufacturing Industry</option>
                                                <option value="810">Media And Entertainment Industry</option>
                                                <option value="811">Metals And Mining Industry</option>
                                                <option value="812">ONGC Oil And Natural Gas Industry</option>
                                                <option value="813">Pharmaceuticals Industry</option>
                                                <option value="814">Power Industry</option>
                                                <option value="815">Railway Industry</option>
                                                <option value="816">Real Estate Industry</option>
                                                <option value="817">Retail Industry</option>
                                                <option value="818">Science And Technology Industry</option>
                                                <option value="819">Services Industry</option>
                                                <option value="820">Telecommunications Industry</option>
                                                <option value="821">Textiles Industry</option>
                                                <option value="822">Tourism And Hospitality Industry</option>
                                                <option value="1114">Accounting</option>
                                                <option value="1115">Interior Designing</option>
                                                <option value="1116">Banking</option>
                                                <option value="1117">Content Writing</option>
                                                <option value="1118">Consultant</option>
                                                <option value="1119">Engineering</option>
                                                <option value="1120">Export &amp; Import</option>
                                                <option value="1121">Merchandiser</option>
                                                <option value="1122">Security</option>
                                                <option value="1123">HR</option>
                                                <option value="1124">Hotel</option>
                                                <option value="1125">Application Programming</option>
                                                <option value="1126">Client Server</option>
                                                <option value="1128">ERP</option>
                                                <option value="1129">VLSI</option>
                                                <option value="1130">MainFrames</option>
                                                <option value="1131">Middleware</option>
                                                <option value="1132">Mobile </option>
                                                <option value="1133">Network Admistrator</option>
                                                <option value="1134">Testing</option>
                                                <option value="1135">System Programming</option>
                                                <option value="1136">EDP</option>
                                                <option value="1137">BPO/KPO</option>
                                                <option value="1138">Legal</option>
                                                <option value="1139">Logistics</option>
                                                <option value="1140">Sales</option>
                                                <option value="1141">Secretary</option>
                                                <option value="1142">Corporate Planning</option>
                                                <option value="1143">Site Engineering</option>
                                                <option value="1144">Graphic Designer</option>
                                                <option value="1145">Business Intelligence</option>
                                                <option value="1146">Shipping</option>
                                                <option value="1147">Analytics</option>
                                                <option value="1148">Airline</option>
                                                <option value="1723">Robotics</option>
                                                <option value="1724">Writing And Editing</option>
                                                <option value="1725">Wireless</option>
                                                <option value="1726">Wholesale </option>
                                                <option value="1727">Veterinary</option>
                                                <option value="1728">Venture Capital &amp; Private Equity</option>
                                                <option value="1729">Transportation/ Trucking/ Railroad</option>
                                                <option value="1730">Translation And Localization</option>
                                                <option value="1731">Textiles</option>
                                                <option value="1732">Staffing And Recruiting</option>
                                                <option value="1733">Sports </option>
                                                <option value="1734">Sporting Goods</option>
                                                <option value="1735">Ship Building</option>
                                                <option value="1736">Security And Investigations</option>
                                                <option value="1737">Public Relations And Communication</option>
                                                <option value="1803">Information Technology</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10 selectdesign">
                                            <label className="font-sm color-text-mutted mb-5">Job Function</label>
                                            <select id="job_category" name="job_category" className="form-input form-control" data-placeholder="Select Job Function" data-select2-id="job_category" tabIndex="-1" aria-hidden="true">
                                                <option data-select2-id="15"></option>
                                                <option value="825">Accounts Executive</option>
                                                <option value="826">Accounting</option>
                                                <option value="827">Accountant</option>
                                                <option value="828">Accounts Manager</option>
                                                <option value="829">Accounts Payable</option>
                                                <option value="830">Accounts Assistant</option>
                                                <option value="831">HR Generalist Activities</option>
                                                <option value="832">Accounts &amp; Finance Manager</option>
                                                <option value="833">Investment Banking</option>
                                                <option value="834">Banking Operations</option>
                                                <option value="835">Private Banking</option>
                                                <option value="836">Automobile</option>
                                                <option value="837">AutoCAD</option>
                                                <option value="838">Automobile Engineering</option>
                                                <option value="839">Automobile Sales</option>
                                                <option value="840">Automobile Engineer</option>
                                                <option value="841">Automobile Services</option>
                                                <option value="842">Automobile Service Engineer </option>
                                                <option value="843">Automobile Production</option>
                                                <option value="844">Automobile Technician</option>
                                                <option value="845">Automobile Components</option>
                                                <option value="846">Automobile Maintenance </option>
                                                <option value="847">Auto Mechanic</option>
                                                <option value="848">Automobile Trainer</option>
                                                <option value="849">Aviation Security</option>
                                                <option value="850">Aviation Management</option>
                                                <option value="851">Assistant Aviation Officer</option>
                                                <option value="852">Aviation Fire Fighter</option>
                                                <option value="853">Bank Reconciliation</option>
                                                <option value="854">Corporate Banking</option>
                                                <option value="855">Banking Sales</option>
                                                <option value="856">Banking Officer</option>
                                                <option value="857">Branch Banking</option>
                                                <option value="858">Retail Banking</option>
                                                <option value="859">Phone Banking Officer</option>
                                                <option value="860">Core Banking</option>
                                                <option value="861">Phone Banking</option>
                                                <option value="862">Retail Banking Operations</option>
                                                <option value="863">Branch Banking Operations</option>
                                                <option value="864">Cement Plant</option>
                                                <option value="865">Cement Company</option>
                                                <option value="866">Cement Marketing</option>
                                                <option value="867">Cement Sales</option>
                                                <option value="868">Consumer Durables</option>
                                                <option value="869">Consumer Electronics</option>
                                                <option value="870">Consumer Behaviour</option>
                                                <option value="871">Consumer Banking</option>
                                                <option value="872">Consumer Marketing</option>
                                                <option value="873">Consumer Complaints</option>
                                                <option value="874">Consumer Research</option>
                                                <option value="875">Consumer Sales</option>
                                                <option value="876">Consumer Finance</option>
                                                <option value="877">Ecommerce Manager</option>
                                                <option value="878">Ecommerce Development</option>
                                                <option value="879">Ecommerce Consultant</option>
                                                <option value="880">Ecommerce Executive</option>
                                                <option value="881">Physical Education Teacher</option>
                                                <option value="882">Special Education Teacher</option>
                                                <option value="883">Education And Admissions Sales</option>
                                                <option value="884">Educational Marketing</option>
                                                <option value="885">Educational Counsellor</option>
                                                <option value="886">Special Educator</option>
                                                <option value="887">Education Consultant</option>
                                                <option value="888">Design Engineer </option>
                                                <option value="889">Test Engineer</option>
                                                <option value="890">Mechanical Engineer</option>
                                                <option value="891">Civil Engineer</option>
                                                <option value="892">Technical Support Engineer</option>
                                                <option value="893">Network Engineer</option>
                                                <option value="894">Service Engineer</option>
                                                <option value="895">Software Engineer</option>
                                                <option value="896">Desktop Support Engineer</option>
                                                <option value="897">Production Engineer</option>
                                                <option value="898">Site Engineer</option>
                                                <option value="899">Financial Analyst</option>
                                                <option value="900">Finance Executive</option>
                                                <option value="901">Chief Financial Officer</option>
                                                <option value="902">Accounts &amp; Finance Executive</option>
                                                <option value="903">Finance Manger</option>
                                                <option value="904">Financial Analysis</option>
                                                <option value="905">Financial Accountant</option>
                                                <option value="906">Financial Services</option>
                                                <option value="907">MBA Finance</option>
                                                <option value="908">Trade Finance</option>
                                                <option value="909">Financial Controller</option>
                                                <option value="910">Financial Planning</option>
                                                <option value="911">Financial Management</option>
                                                <option value="912">FMCG Sales</option>
                                                <option value="913">FMCG Marketing</option>
                                                <option value="914">FMCG Manager</option>
                                                <option value="915">FMCG Operations</option>
                                                <option value="916">Jewellery Designer</option>
                                                <option value="917">Health Care Management</option>
                                                <option value="918">Health Care Manager</option>
                                                <option value="919">Health Care Services</option>
                                                <option value="920">Health Care BPO</option>
                                                <option value="921">Healthcare Marketing</option>
                                                <option value="922">Health Care Executive</option>
                                                <option value="923">Health Care Assistant</option>
                                                <option value="924">Health Care Officer</option>
                                                <option value="925">Global Health Care Products</option>
                                                <option value="926">IT Infrastructure</option>
                                                <option value="927">IT Infrastructure Manager</option>
                                                <option value="928">IT Infrastructure Management</option>
                                                <option value="929">Infrastructure Manager</option>
                                                <option value="930">IT Infrastructure Support</option>
                                                <option value="931">Head IT Infrastructure </option>
                                                <option value="932"> Infrastructure Engineer</option>
                                                <option value="933">Project Manager IT Infrastructure </option>
                                                <option value="934">Infrastructure Architect</option>
                                                <option value="935">Remote Infrastructure Management</option>
                                                <option value="936">Infrastructure Project Manager</option>
                                                <option value="937">Infrastructure Services</option>
                                                <option value="938">Insurance </option>
                                                <option value="939">General Insurance</option>
                                                <option value="940">Life Insurance</option>
                                                <option value="941">Health Insurance</option>
                                                <option value="942">Insurance Sales</option>
                                                <option value="943">Insurance Banking</option>
                                                <option value="944">Insurance Manager</option>
                                                <option value="945">Insurance Broking</option>
                                                <option value="946">Insurance Underwriting</option>
                                                <option value="947">Insurance Advisor</option>
                                                <option value="948">Insurance Trainee</option>
                                                <option value="949">Motor Insurance</option>
                                                <option value="950">Insurance Claims</option>
                                                <option value="951">Software Developer</option>
                                                <option value="952">Software Tesitng</option>
                                                <option value="953">Software Services</option>
                                                <option value="954">Software Developement</option>
                                                <option value="955">Software Test Engineer</option>
                                                <option value="956">System Software  Engineer</option>
                                                <option value="957">Software Engineering Analyst</option>
                                                <option value="958">Senior Software Engineer</option>
                                                <option value="959">Software Tester</option>
                                                <option value="960">Software  Sales</option>
                                                <option value="961">Software Programmer</option>
                                                <option value="962">Embedded Software Engineer</option>
                                                <option value="963">Software Engineering Senior Analyst</option>
                                                <option value="964">General Manager Manufacturing</option>
                                                <option value="965">Manufacturing Engineer</option>
                                                <option value="966">Automotive Manufactures</option>
                                                <option value="967">Lean Manufacturing</option>
                                                <option value="968">Manufacturing Manager</option>
                                                <option value="969">Head Manufacturing</option>
                                                <option value="970">Manufacturing Process</option>
                                                <option value="971">API Manufacturing</option>
                                                <option value="972">Manufacturing Chemist</option>
                                                <option value="973">Gear Manufacturing</option>
                                                <option value="974">Senior Manager Manufacturing</option>
                                                <option value="975">Contract Manufacturing</option>
                                                <option value="976">Manufacturing Executive</option>
                                                <option value="977">Social Media Marketing</option>
                                                <option value="978">Media Planning</option>
                                                <option value="979">Media Sales</option>
                                                <option value="980">Media Relations</option>
                                                <option value="981">Digital Media</option>
                                                <option value="982">Social Media Manager</option>
                                                <option value="983">Electronic Media</option>
                                                <option value="984">Media Marketing</option>
                                                <option value="985">Media Manager</option>
                                                <option value="986">Media Research</option>
                                                <option value="987">Media Buying</option>
                                                <option value="988">Social Media Optimization</option>
                                                <option value="989">Metallurgist</option>
                                                <option value="990">Sheet Metal Design</option>
                                                <option value="991">Metallurgical Testing</option>
                                                <option value="992">Metallurgical Lab</option>
                                                <option value="993">Senior Metallurgist</option>
                                                <option value="994">Sheet Metal Worker</option>
                                                <option value="995">Metallurgy Engineer</option>
                                                <option value="996">Metallurgical Engineer</option>
                                                <option value="997">Welding Metallurgy</option>
                                                <option value="998">Mechanical Metallurgy</option>
                                                <option value="999">Data Mining</option>
                                                <option value="1000">Mining Engineer</option>
                                                <option value="1001">Coal Mining</option>
                                                <option value="1002">Mining Engineering</option>
                                                <option value="1003">Account Mining</option>
                                                <option value="1004">Text Mining</option>
                                                <option value="1005">Senior Mining Engineer</option>
                                                <option value="1006">Mining Geologist</option>
                                                <option value="1007">Iron Ore Mining</option>
                                                <option value="1008">Junior Mining Engineer</option>
                                                <option value="1009">Mining Operations</option>
                                                <option value="1010">Senior Mining Manger</option>
                                                <option value="1011">Pharmacy Manager</option>
                                                <option value="1012">Pharmacy Incharge</option>
                                                <option value="1013">Clinical Pharmacy</option>
                                                <option value="1014">Pharmacy Assistant</option>
                                                <option value="1015">M Pharmacy  Fresher</option>
                                                <option value="1016">Pharmacy Benefit Analyst</option>
                                                <option value="1017">Pharmacy Technician</option>
                                                <option value="1018">Pharmacy Aide</option>
                                                <option value="1019">Pharmacy Supervisor</option>
                                                <option value="1020">Pharmacy Officer</option>
                                                <option value="1021">Pharmaceutical Sales</option>
                                                <option value="1022">Hospital Pharmacist</option>
                                                <option value="1023">Pharmaceutical Production</option>
                                                <option value="1024">Pharmacology</option>
                                                <option value="1025">Pharmaceutical Quality Assurance</option>
                                                <option value="1026">Pharmacovigilance  Associate</option>
                                                <option value="1027">Retail Pharmacist</option>
                                                <option value="1028">Clinical Pharmacist</option>
                                                <option value="1029">Assistant Pharmacist</option>
                                                <option value="1030">Real Estate Sales</option>
                                                <option value="1031">Real Estate Marketing</option>
                                                <option value="1032">Real Estate Manager</option>
                                                <option value="1033">Real Estate Construction</option>
                                                <option value="1034">Real Estate Consultant</option>
                                                <option value="1035">Commercial Real Estate</option>
                                                <option value="1036">Retail Store Manager</option>
                                                <option value="1037"> Retail Sales</option>
                                                <option value="1038">Retail Operations</option>
                                                <option value="1039">Retail Manager</option>
                                                <option value="1040">Retail Sales Executive</option>
                                                <option value="1041">Retail Merchandiser</option>
                                                <option value="1042">Retail Marketing</option>
                                                <option value="1043">Retail Merchandising</option>
                                                <option value="1044">Life Sciences</option>
                                                <option value="1045">Environmental Sciences</option>
                                                <option value="1046">Data Science</option>
                                                <option value="1047">Political Science</option>
                                                <option value="1048">Material Science</option>
                                                <option value="1049">Chief Technology Officer</option>
                                                <option value="1050">Food Technologist</option>
                                                <option value="1051">Web Technologist</option>
                                                <option value="1052">General Manager Information Technology</option>
                                                <option value="1053">Associate Information Technology Engineer</option>
                                                <option value="1054">Associate Information Technology Consultant</option>
                                                <option value="1055">Dairy Technologist</option>
                                                <option value="1056">Customer Service</option>
                                                <option value="1057">Customer Service Executive</option>
                                                <option value="1058">Service Supervisor</option>
                                                <option value="1059">Service Manager</option>
                                                <option value="1060">Customer Service Manager</option>
                                                <option value="1061">Service Desk Support</option>
                                                <option value="1062">Service Delivery Leader</option>
                                                <option value="1063">Service Delivery Manager</option>
                                                <option value="1064">Service Delivery Boys/Girls</option>
                                                <option value="1065">Telecom Engineer</option>
                                                <option value="1066">Telecalling</option>
                                                <option value="1067">Telemarketing</option>
                                                <option value="1068">Telecom Sales</option>
                                                <option value="1069">Telecom Billing</option>
                                                <option value="1070">Telecom Testing</option>
                                                <option value="1071">Telesales</option>
                                                <option value="1072">Telephone Operator</option>
                                                <option value="1073">Textile Designer</option>
                                                <option value="1074">Textile Designing</option>
                                                <option value="1075">Textile Inspector</option>
                                                <option value="1076">Textile Testing</option>
                                                <option value="1077">Textile Consultant</option>
                                                <option value="1078">Senior Textile Designer</option>
                                                <option value="1079">Assistant Textile Designer</option>
                                                <option value="1080">Tour Manager</option>
                                                <option value="1081">Tour Executive</option>
                                                <option value="1082">Tour Management Executive</option>
                                                <option value="1083">Tour Operator</option>
                                                <option value="1084">Tour Guide</option>
                                                <option value="1085">Tour Package Consultant</option>
                                                <option value="1086">Inbound Tour Executive</option>
                                                <option value="1087">Medical Tourist</option>
                                                <option value="1088">Hospitality Manager</option>
                                                <option value="1089">Hospitality Trainer</option>
                                                <option value="1090">Hospitality Management</option>
                                                <option value="1091">Hospitality Sales</option>
                                                <option value="1092">Hospitality Executive</option>
                                                <option value="1093">Hospitality Assistant</option>
                                                <option value="1094">IT Manager</option>
                                                <option value="1095">IT Recruiter</option>
                                                <option value="1096">IT Head</option>
                                                <option value="1097">IT Management</option>
                                                <option value="1098">IT Support</option>
                                                <option value="1099">IT Sales</option>
                                                <option value="1100">IT Service Desk</option>
                                                <option value="1101">IT Support Engineer</option>
                                                <option value="1102">IT Security</option>
                                                <option value="1103">IT Support Executive</option>
                                                <option value="1104">IT System Engineer</option>
                                                <option value="1105">IT Sales Manager</option>
                                                <option value="1106">IT Service Delivery</option>
                                                <option value="1107">IT Service Delivery Management</option>
                                                <option value="1108">IT Stratergy</option>
                                                <option value="1109">IT Customer Care</option>
                                                <option value="1110">IT Service Sales</option>
                                                <option value="1111">IT Inside Sales</option>
                                                <option value="1112">IT Consultant</option>
                                                <option value="1113">IT Global Head</option>
                                                <option value="1149">Interior Designer</option>
                                                <option value="1150">Architect Interior Designer</option>
                                                <option value="1151">Interior Designing</option>
                                                <option value="1152">Interior Designer Project Manager</option>
                                                <option value="1153">3D Interior Designer</option>
                                                <option value="1154">Senior Interior Designer</option>
                                                <option value="1155">Interior Architect</option>
                                                <option value="1156">Interior Site Supervisor</option>
                                                <option value="1157">Assistant Interior Designer</option>
                                                <option value="1158">Interior Decoration</option>
                                                <option value="1159">Interior Project Manager</option>
                                                <option value="1160">Associate Interior Designer</option>
                                                <option value="1161">Interior Architecture</option>
                                                <option value="1162">Content Writer</option>
                                                <option value="1163">Content Developer</option>
                                                <option value="1164">Freelance Content Writer</option>
                                                <option value="1165">Content Management</option>
                                                <option value="1166">Content Editor</option>
                                                <option value="1167">Content Manager</option>
                                                <option value="1168">Technical Content Developer</option>
                                                <option value="1169">Content Analysis</option>
                                                <option value="1170">Content Development</option>
                                                <option value="1171">Content Marketing</option>
                                                <option value="1172">Content Analyst</option>
                                                <option value="1173">Executive Import &amp; Export</option>
                                                <option value="1174">Import &amp; Export Manager</option>
                                                <option value="1175">Import &amp; Export Executive</option>
                                                <option value="1176">Import &amp; Export Officer</option>
                                                <option value="1177">Import &amp; Export Assistant</option>
                                                <option value="1178">Air Imports</option>
                                                <option value="1179">Import Purchase</option>
                                                <option value="1180">Merchandising</option>
                                                <option value="1181">Visual Merchandiser</option>
                                                <option value="1182">Assistant Merchandiser</option>
                                                <option value="1183">Merchandising Manager</option>
                                                <option value="1184">Apparel Merchandising</option>
                                                <option value="1185">Senior Merchandiser</option>
                                                <option value="1186">Garment Merchandiser</option>
                                                <option value="1187">Security Officer</option>
                                                <option value="1188">Security Manager</option>
                                                <option value="1189">SAP Security</option>
                                                <option value="1190">Chief Security Officer</option>
                                                <option value="1191">Information Security</option>
                                                <option value="1192">Security Supervisor</option>
                                                <option value="1193">Security Guard</option>
                                                <option value="1194">Network Security</option>
                                                <option value="1195">Head Security</option>
                                                <option value="1196">Network Security Engineer</option>
                                                <option value="1197">Security Management</option>
                                                <option value="1198">SAP Security Consultant</option>
                                                <option value="1199">HR Executive</option>
                                                <option value="1200">HR Manager</option>
                                                <option value="1201">HR Generalist</option>
                                                <option value="1202">HR Recruitment Executive</option>
                                                <option value="1203">SAP HR</option>
                                                <option value="1204">HR Administration</option>
                                                <option value="1205">HR Payroll Executive</option>
                                                <option value="1206">HR Process Executive</option>
                                                <option value="1207">HR Assistant Manager</option>
                                                <option value="1208">Manager HR &amp; Administration</option>
                                                <option value="1209">HR Analyst</option>
                                                <option value="1210">HR &amp; Administration Officer</option>
                                                <option value="1211">Senior Executive &amp; HR Administrator</option>
                                                <option value="1212">HR Administration Officer</option>
                                                <option value="1213">HR Assistant</option>
                                                <option value="1214">HR &amp; IT Recruiter</option>
                                                <option value="1215">Hotel Manager</option>
                                                <option value="1216">Hotel Sales Manger</option>
                                                <option value="1217">Hotel Sale Business Development Manager</option>
                                                <option value="1218">Hotel Operations</option>
                                                <option value="1219">Housekeeping Supervisor</option>
                                                <option value="1220">Assistant Manager</option>
                                                <option value="1221">Hotel  Booking Consultant</option>
                                                <option value="1222">Application Support</option>
                                                <option value="1223">WebSphere Application Server</option>
                                                <option value="1224">Application Packaging</option>
                                                <option value="1225">Application Engineer</option>
                                                <option value="1226">Mobile Application Developer</option>
                                                <option value="1227">Mobile Application Testing</option>
                                                <option value="1228">Android Application Development </option>
                                                <option value="1229">Android Application Developer</option>
                                                <option value="1230">IOS Application Developer</option>
                                                <option value="1231">Application Support Engineer</option>
                                                <option value="1232">Oracle Application Technical Consultant</option>
                                                <option value="1233">Application Programming</option>
                                                <option value="1234">Android Application</option>
                                                <option value="1235">Client Servicing</option>
                                                <option value="1236">Client Relationship Manager</option>
                                                <option value="1237">Client Management</option>
                                                <option value="1238">Client Acquisition</option>
                                                <option value="1239">Manager Client Servicing</option>
                                                <option value="1240">Client Servicing Executive</option>
                                                <option value="1241">Client Onboarding</option>
                                                <option value="1242">Client Reporting</option>
                                                <option value="1243">Client Relationship</option>
                                                <option value="1244">Client Server</option>
                                                <option value="1245">Client Technical Support Associate</option>
                                                <option value="1246">Client Engagement</option>
                                                <option value="1247">Client Account Manager</option>
                                                <option value="1248">Client Acquisition Manager</option>
                                                <option value="1249">Client Acquisition Executive</option>
                                                <option value="1250">Associate Client Acquisition</option>
                                                <option value="1251">Client Interaction</option>
                                                <option value="1252">Client Interfacing</option>
                                                <option value="1253">Oracle DBA</option>
                                                <option value="1254">SQL DBA</option>
                                                <option value="1255">Oracle Apps DBA</option>
                                                <option value="1256">SQL Server DBA</option>
                                                <option value="1257">Database Administration</option>
                                                <option value="1258">Terra Data DBA</option>
                                                <option value="1259">DBA Manager</option>
                                                <option value="1260">Sybase DBA</option>
                                                <option value="1261">Apps DBA</option>
                                                <option value="1262">Db2 Dba</option>
                                                <option value="1263">DBA Adminstrator</option>
                                                <option value="1264">Oracle DBA Consultant</option>
                                                <option value="1265">E Commerce Manager</option>
                                                <option value="1266">E Commerce Development</option>
                                                <option value="1267">E Commerce Consultant</option>
                                                <option value="1268">E Commerce Executive</option>
                                                <option value="1269">ERP Implementation</option>
                                                <option value="1270">Tally ERP </option>
                                                <option value="1271">ERP Functional Consultant</option>
                                                <option value="1272">ERP  Manager</option>
                                                <option value="1273">ERP  Sales</option>
                                                <option value="1274">Oracle ERP </option>
                                                <option value="1275">ERP Navision</option>
                                                <option value="1276">SAP ERP</option>
                                                <option value="1277">ERP Executive</option>
                                                <option value="1278">ERP  Testing</option>
                                                <option value="1279">ERP  Developer</option>
                                                <option value="1280">ERP Technical Consultant</option>
                                                <option value="1281">ERP Project Manager </option>
                                                <option value="1282">BPO Non Voice Process</option>
                                                <option value="1283">BPO Semi Voice Process</option>
                                                <option value="1284">BPO US Process</option>
                                                <option value="1285">BPO International Call Centre</option>
                                                <option value="1286">BPO Inbound Process</option>
                                                <option value="1287">BPO Customer Service</option>
                                                <option value="1288">BPO Call Centre</option>
                                                <option value="1289">BPO Back Office</option>
                                                <option value="1290">BPO Executive</option>
                                                <option value="1291">BPO Process Associate</option>
                                                <option value="1292">BPO Team Leader</option>
                                                <option value="1293">BPO Information Processing  Specialist</option>
                                                <option value="1294"> Legal Manger</option>
                                                <option value="1295">Legal Advisor</option>
                                                <option value="1296">Legal Officer</option>
                                                <option value="1297">Legal Executive</option>
                                                <option value="1298">Company Secretary &amp; Legal</option>
                                                <option value="1299">Assistant Manager Legal</option>
                                                <option value="1300">Legal Assistant</option>
                                                <option value="1301">Legal Consultant</option>
                                                <option value="1302">Legal Associate</option>
                                                <option value="1303">General Manager Legal</option>
                                                <option value="1304">Advocate Legal Adviser</option>
                                                <option value="1305">Legal Head</option>
                                                <option value="1306">Legal Advisior</option>
                                                <option value="1307">Marketing Manager</option>
                                                <option value="1308">Digital Marketing Assistant Manager</option>
                                                <option value="1309">Digital Marketing Senior Manager</option>
                                                <option value="1310">Online Marketing Assistant Manager</option>
                                                <option value="1311">Online  Marketing Senior Manager</option>
                                                <option value="1312">Direct Marketing Executive</option>
                                                <option value="1313">Manager Sales &amp; Marketing</option>
                                                <option value="1314">Pharma Sales Marketing</option>
                                                <option value="1315">Email  Marketing</option>
                                                <option value="1316">Internet Marketing</option>
                                                <option value="1317">Marketing Analyst</option>
                                                <option value="1318">Digital Marketing Analyst</option>
                                                <option value="1319">Marketing &amp; Business Development Executive</option>
                                                <option value="1320">Online Marketing Analyst</option>
                                                <option value="1321">Pharmacist</option>
                                                <option value="1322">Maintenance Engineer</option>
                                                <option value="1323">Electrical Maintenance Engineer</option>
                                                <option value="1324">Mechanical Maintenance Engineer</option>
                                                <option value="1325">Maintenance Manager</option>
                                                <option value="1326">Plant Maintenance </option>
                                                <option value="1327">Utility Maintenance </option>
                                                <option value="1328">Maintenance Fitter</option>
                                                <option value="1329">Maintenance Supervisor</option>
                                                <option value="1330">CNC Maintenance </option>
                                                <option value="1331">Boiler Maintenance</option>
                                                <option value="1332">Maintenance Officer</option>
                                                <option value="1333">Maintenance Technician</option>
                                                <option value="1334">Maintenance Incharge</option>
                                                <option value="1335">Store Maintenance</option>
                                                <option value="1336">Database Maintenance</option>
                                                <option value="1337">Logistics Executive</option>
                                                <option value="1338">Logistics Manager</option>
                                                <option value="1339">Head Logistics</option>
                                                <option value="1340">Logistics Management</option>
                                                <option value="1341">Logistics Operations</option>
                                                <option value="1342">Logistics Officer</option>
                                                <option value="1343">Assistant Manager Logistics</option>
                                                <option value="1344">Ware House &amp; Logistics Executive</option>
                                                <option value="1345">Ware House &amp; Logistics Manager</option>
                                                <option value="1346">Manger &amp; Stores Logistics</option>
                                                <option value="1347">Export Logistics</option>
                                                <option value="1348">Sale Manager</option>
                                                <option value="1349">Sale Executive</option>
                                                <option value="1350">Area Sales Manger</option>
                                                <option value="1351">Sales Coordinator</option>
                                                <option value="1352">Sales Officer</option>
                                                <option value="1353">Sales Engineer</option>
                                                <option value="1354">Sales Assistant</option>
                                                <option value="1355">Direct Sales</option>
                                                <option value="1356">National Sales Manager</option>
                                                <option value="1357">Regional Sales Manager</option>
                                                <option value="1358">Territory Sales Manager</option>
                                                <option value="1359">Zonal Sales Manager</option>
                                                <option value="1360">Salesman</option>
                                                <option value="1361">Branch Sales Manager</option>
                                                <option value="1362">Head Sales &amp; Marketing</option>
                                                <option value="1363">Corporate Sales Manager</option>
                                                <option value="1364">Institutional Sales Manager</option>
                                                <option value="1365">Company Secretary</option>
                                                <option value="1366">Executive Secretary </option>
                                                <option value="1367">Personal Secretary</option>
                                                <option value="1368">Assistant Company Secretary</option>
                                                <option value="1369">Company Secretary Trainee</option>
                                                <option value="1370">Secretarial Executive</option>
                                                <option value="1371">Secretarial Operations</option>
                                                <option value="1372">Private Secretary</option>
                                                <option value="1373">Secretarial Assistant</option>
                                                <option value="1374">CFO &amp; Company Secretary</option>
                                                <option value="1375">Secretary</option>
                                                <option value="1376">Accounting Tax Company Secretary</option>
                                                <option value="1377">Secretary PA</option>
                                                <option value="1378">Practising Company Secretary</option>
                                                <option value="1379">Deputy Company Secretary</option>
                                                <option value="1380">Secretary General</option>
                                                <option value="1381">Senior Secretary</option>
                                                <option value="1382">Corporate Trainer</option>
                                                <option value="1383">Corporate Sales</option>
                                                <option value="1384">Corporate HR</option>
                                                <option value="1385">Corporate Communication Manager</option>
                                                <option value="1386">Corporate Accounting</option>
                                                <option value="1387">Corporate Agency Manager</option>
                                                <option value="1388">Manager Corporate Affairs</option>
                                                <option value="1389">Corporate Accounts Manager</option>
                                                <option value="1390">Corporate Advisor</option>
                                                <option value="1391">Senior Corporate Agency Manger</option>
                                                <option value="1392">Sales Development Manager Corporate Agency</option>
                                                <option value="1393">Corporate Accountant</option>
                                                <option value="1394">Corporate Branding</option>
                                                <option value="1395">Relationship Manager Corporate Banking</option>
                                                <option value="1396">Corporate Communication Executive</option>
                                                <option value="1397">Corporate Consultants</option>
                                                <option value="1398">Manager Corporate Finance</option>
                                                <option value="1399">Corporate Finance Executive</option>
                                                <option value="1400">Senior Manager Corporate Finance</option>
                                                <option value="1401">Deputy Manager Corporate HR</option>
                                                <option value="1402">Corporate HR Executive</option>
                                                <option value="1403">Senior Manager Corporate HR</option>
                                                <option value="1404">Corporate Lawyer</option>
                                                <option value="1405">Corporate Loans</option>
                                                <option value="1406">Corporate Law</option>
                                                <option value="1407">Corporate PR</option>
                                                <option value="1408">Corporate Purchasing</option>
                                                <option value="1409">Manager Corporate Planning</option>
                                                <option value="1410">Executive Corporate Quality Assurance</option>
                                                <option value="1411">Corporate Quality Assurance</option>
                                                <option value="1412">Civil Site Engineer</option>
                                                <option value="1413">Site Supervisor</option>
                                                <option value="1414">Electrical Site Engineer</option>
                                                <option value="1415">Site Accountant</option>
                                                <option value="1416">Site Supervision</option>
                                                <option value="1417">Civil Site Supervisior</option>
                                                <option value="1418">Site Incharge</option>
                                                <option value="1419">Site Administrator</option>
                                                <option value="1420">Site Architect</option>
                                                <option value="1421">Site Agent</option>
                                                <option value="1422">Site Analysis</option>
                                                <option value="1423">Site &amp; Billing Engineer</option>
                                                <option value="1424">Site Acquisition Officer</option>
                                                <option value="1425">Site Coordinator</option>
                                                <option value="1426">Site Clerk</option>
                                                <option value="1427">Site Director</option>
                                                <option value="1428">Site Foreman</option>
                                                <option value="1429">Site Inspector</option>
                                                <option value="1430">Field Officer</option>
                                                <option value="1431">Field Engineer</option>
                                                <option value="1432">Field Developer</option>
                                                <option value="1433">Field  Director</option>
                                                <option value="1434">Field Service Manager</option>
                                                <option value="1435">Field Sales</option>
                                                <option value="1436">Field Marketing</option>
                                                <option value="1437">Field Boy</option>
                                                <option value="1438">Field Controller</option>
                                                <option value="1439">Field Collections</option>
                                                <option value="1440">Field Development Officer</option>
                                                <option value="1441">Field Executive</option>
                                                <option value="1442">Field Force Area Sales Manager</option>
                                                <option value="1443">Film Production</option>
                                                <option value="1444">Film Director</option>
                                                <option value="1445">TV Production</option>
                                                <option value="1446">Film Making</option>
                                                <option value="1447">Film Editor</option>
                                                <option value="1448">Film Maker</option>
                                                <option value="1449">Film Advertisers</option>
                                                <option value="1450">Airline Attendant</option>
                                                <option value="1451">Airline Process</option>
                                                <option value="1452">Air Hostess</option>
                                                <option value="1453">Air Flight Attendants</option>
                                                <option value="1454">Airport Ground Staff</option>
                                                <option value="1455">Airport Curbside Customer Service Rep</option>
                                                <option value="1456">Airport Boarding Pass Associates / Customer Service Exe</option>
                                                <option value="1457">Airport Ticket Checking Staff</option>
                                                <option value="1458">Airport Services Supervisor</option>
                                                <option value="1459">Airport Ticketing &amp; Reservation</option>
                                                <option value="1460">Airport Guest Relationship Officer</option>
                                                <option value="1461">Boarding Pass Checking Officer</option>
                                                <option value="1462">Airport Boarding Pass Associate</option>
                                                <option value="1463">Airport Operation Executive</option>
                                                <option value="1464">Airport Air Traffic Controller</option>
                                                <option value="1465">Airport Baggage Handler</option>
                                                <option value="1466">Airline Aircraft Fueler</option>
                                                <option value="1467">Airline Cabin Maintenance / Service Person</option>
                                                <option value="1468">Airline  Flight Dispatcher</option>
                                                <option value="1469">Airline Ground / Airport Station Attendant</option>
                                                <option value="1470">Airline Aviation Meteorologist</option>
                                                <option value="1471">Airline Passenger Service Agent</option>
                                                <option value="1472">Airline Crew Schedule Coordinator</option>
                                                <option value="1473">Airline Airline Station Agent</option>
                                                <option value="1474">Airline Airline Flight Instructor</option>
                                                <option value="1475">Airline Human Resources</option>
                                                <option value="1476">Graphic Designer</option>
                                                <option value="1477">Graphic &amp; Web Designer</option>
                                                <option value="1478">Senior Graphic Designer</option>
                                                <option value="1479">Freelance Graphic Designer</option>
                                                <option value="1480">Creative Graphic Designer</option>
                                                <option value="1481">Motion Graphic Designer</option>
                                                <option value="1482">Multimedia Designer</option>
                                                <option value="1483">Logo Designer</option>
                                                <option value="1484">Brand Identity Designer</option>
                                                <option value="1485">Creative/Art Director</option>
                                                <option value="1486">Flash Designer</option>
                                                <option value="1487">Photo Editing/Photoshop Artist</option>
                                                <option value="1488">Environmental Graphic Design</option>
                                                <option value="1489">Packaging Graphic Design</option>
                                                <option value="1490">Website Graphic Design</option>
                                                <option value="1491">Advertising Graphic Design</option>
                                                <option value="1492"> Book Cover Design</option>
                                                <option value="1493"> Brochure Design</option>
                                                <option value="1494">Label Design</option>
                                                <option value="1495">Mobile App Design</option>
                                                <option value="1496">Stationery Design</option>
                                                <option value="1497">Vehicle Wrap Graphic Design</option>
                                                <option value="1498">Shipping Manager</option>
                                                <option value="1499">Shipping Executive</option>
                                                <option value="1500">Shipping Assistant</option>
                                                <option value="1501">General Crew</option>
                                                <option value="1502">Marine Engineer</option>
                                                <option value="1503">Deck Officer</option>
                                                <option value="1504">Marine Cook</option>
                                                <option value="1505">Shipbuilding And Repair</option>
                                                <option value="1506">Port Operations</option>
                                                <option value="1507">Deck Cadet</option>
                                                <option value="1508">Port Captain</option>
                                                <option value="1509">Manager-Port Logistics</option>
                                                <option value="1510">Vessel Manager - Offshore</option>
                                                <option value="1511">Superintendent Technical Containers</option>
                                                <option value="1512">Vetting Manager</option>
                                                <option value="1513">Marine Superintendent </option>
                                                <option value="1514">Fleet Manager</option>
                                                <option value="1515">Marine Marketing Service Executive</option>
                                                <option value="1516">Trainee Superintendent - Offshore</option>
                                                <option value="1517">Business Analyst</option>
                                                <option value="1518">Financial  Analyst</option>
                                                <option value="1519">Data  Analyst</option>
                                                <option value="1520">Research  Analyst</option>
                                                <option value="1521">Quality  Analyst</option>
                                                <option value="1522">System  Analyst</option>
                                                <option value="1523">Credit  Analyst</option>
                                                <option value="1524">Market Research  Analyst</option>
                                                <option value="1525">Software Engineering  Analyst</option>
                                                <option value="1526">Equity Research  Analyst</option>
                                                <option value="1527">MIS  Analyst</option>
                                                <option value="1528">Senior Business  Analyst</option>
                                                <option value="1529">SEO  Analyst</option>
                                                <option value="1530">SAS  Analyst</option>
                                                <option value="1531">Business Analytics</option>
                                                <option value="1532">Data Analytics</option>
                                                <option value="1533">Web Analytics</option>
                                                <option value="1534">Analytics Manager</option>
                                                <option value="1535">Google Anaytics</option>
                                                <option value="1536">HR Analytics</option>
                                                <option value="1537">Big Data Analytics</option>
                                                <option value="1538">Marketing Analytics</option>
                                                <option value="1539">Predictive Analytics</option>
                                                <option value="1540">Fractal Analytics</option>
                                                <option value="1541">Digital Analytics</option>
                                                <option value="1542">Customer Analytics</option>
                                                <option value="1543">Text Analytics</option>
                                                <option value="1544">Risk Analytics</option>
                                                <option value="1545">Risk Management</option>
                                                <option value="1546">Risk Analyst</option>
                                                <option value="1547">Credit Risk</option>
                                                <option value="1548">Operational Risk Management</option>
                                                <option value="1549">Risk Counsulting</option>
                                                <option value="1550">Risk Assesment</option>
                                                <option value="1551">Risk Manager</option>
                                                <option value="1552">Financial Risk Management</option>
                                                <option value="1553">Risk Advisory</option>
                                                <option value="1554">Enterprise Risk Management</option>
                                                <option value="1555">Transaction Risk Investigator</option>
                                                <option value="1556">Manager Risk Management</option>
                                                <option value="1557">Food Court Manager</option>
                                                <option value="1558">Professor </option>
                                                <option value="1559">Assistant Professor</option>
                                                <option value="1560">Associate Professor</option>
                                                <option value="1561">Guest Lecturer</option>
                                                <option value="1562">College DEAN</option>
                                                <option value="1563">Visiting Professor</option>
                                                <option value="1564">Clerical Staff</option>
                                                <option value="1565">Clerical Officer</option>
                                                <option value="1566">Clerical Assitant</option>
                                                <option value="1567">Clerical Work</option>
                                                <option value="1568">Back End Officer</option>
                                                <option value="1569">Office Boy</option>
                                                <option value="1570">Back Office Associate</option>
                                                <option value="1571">Back Office Manager</option>
                                                <option value="1572">Office Assistant</option>
                                                <option value="1573">Office Administration</option>
                                                <option value="1574">Front Office Assistant</option>
                                                <option value="1575">Receptionist</option>
                                                <option value="1576">Office Coordinator</option>
                                                <option value="1577">Back Office Computer Operator</option>
                                                <option value="1578">Back Office Coordinator</option>
                                                <option value="1579">Front Office Coordinator</option>
                                                <option value="1580">Officer Cash Remittance</option>
                                                <option value="1581">Chief Administration Officer CAO</option>
                                                <option value="1582">Chief Operations Officer ( COO )</option>
                                                <option value="1583">Scientific Officer</option>
                                                <option value="1584">Officer Chemist</option>
                                                <option value="1585">Front Office Duty Manager</option>
                                                <option value="1586">Dispatch Officer</option>
                                                <option value="1587">Distribution Officer</option>
                                                <option value="1588">Deputy Officer</option>
                                                <option value="1589">District Programme Officer</option>
                                                <option value="1590">Front Office Executive</option>
                                                <option value="1591">Senor Office Finance</option>
                                                <option value="1592">Field Sales Officer </option>
                                                <option value="1593">Front Office Team Leader</option>
                                                <option value="1595">Tele Sales Executive</option>
                                                <option value="1596">Tele Sales Manager</option>
                                                <option value="1597">Tele Sales Officer</option>
                                                <option value="1598">Tele Sales Representative</option>
                                                <option value="1599">Agriculture Marketing </option>
                                                <option value="1600">Agriculture Engineer</option>
                                                <option value="1601">Agriculture Officer</option>
                                                <option value="1602">Agriculture Expert</option>
                                                <option value="1603">Agriculture Banking</option>
                                                <option value="1604">Agriculture Extension Officer</option>
                                                <option value="1605">Agriculture Finance Officer</option>
                                                <option value="1606">Agriculture Development Officer</option>
                                                <option value="1607">Agricultural Technologist</option>
                                                <option value="1608">Agriculture Assistant</option>
                                                <option value="1609">Agriculture Field Officer</option>
                                                <option value="1610">Horticulture</option>
                                                <option value="1611">Horticulturist</option>
                                                <option value="1612">Horticulture Officer</option>
                                                <option value="1613">Senior Horticulturist</option>
                                                <option value="1614">Horticulture Supervisor</option>
                                                <option value="1615">Horticulture Development Officer</option>
                                                <option value="1616">Assistant Horticulture Officer</option>
                                                <option value="1617">Artificial Intelligence</option>
                                                <option value="1618">Robotics</option>
                                                <option value="1619">Robotics Frame Work</option>
                                                <option value="1620">Robotics Engineer</option>
                                                <option value="1621">Automotive Robotics</option>
                                                <option value="1622">Robotic Wielding</option>
                                                <option value="1623">Robot Programming</option>
                                                <option value="1624">Robotics Trainer</option>
                                                <option value="1625">Robot Operator</option>
                                                <option value="1626">EMC Storage Administrator</option>
                                                <option value="1627">EMC Vnx</option>
                                                <option value="1628">EMC  San</option>
                                                <option value="1629">EMC  Data Storage Systems</option>
                                                <option value="1630">EMC DMX</option>
                                                <option value="1631">EMC Testing</option>
                                                <option value="1632">EMC Symmetrics</option>
                                                <option value="1633">EMC  Clariion</option>
                                                <option value="1634">Software Testing</option>
                                                <option value="1635">Manual Testing</option>
                                                <option value="1636">Automation Testing</option>
                                                <option value="1637">ETL Testing</option>
                                                <option value="1638">QA Testing</option>
                                                <option value="1639">Performance Testing</option>
                                                <option value="1640">Test Manager</option>
                                                <option value="1641">Test Lead</option>
                                                <option value="1642">QTP Testing</option>
                                                <option value="1643">Mobile Testing</option>
                                                <option value="1644">Database Testing</option>
                                                <option value="1645">SAP Testing</option>
                                                <option value="1646">Informatica Developer</option>
                                                <option value="1647">Informatica ETL Developer</option>
                                                <option value="1648">Informatica Testing</option>
                                                <option value="1649">Informatica Consultant</option>
                                                <option value="1650">Informatica Administrator</option>
                                                <option value="1651">Informatica Business Solutions</option>
                                                <option value="1652">Informatica</option>
                                                <option value="1653">Mainframes Testing</option>
                                                <option value="1654">Mainframes Developer</option>
                                                <option value="1655">Mainframe Analyst</option>
                                                <option value="1656">Mainframe Programmer</option>
                                                <option value="1657">Mainframe System Administrator</option>
                                                <option value="1658">Mainframe Trainer</option>
                                                <option value="1659">Mainframes Tester</option>
                                                <option value="1660">Mainframes Storage Administrator</option>
                                                <option value="1661">Mainframes Application Programmer</option>
                                                <option value="1662">Mainframe System Administration Trainee</option>
                                                <option value="1663">PHP Developer</option>
                                                <option value="1664">PHP Web Developer</option>
                                                <option value="1665">PHP Programmer</option>
                                                <option value="1666">Core PHP</option>
                                                <option value="1667">Senior PHP Developer</option>
                                                <option value="1668">PHP Team Leader</option>
                                                <option value="1669">PHP Software Developer</option>
                                                <option value="1670">PHP WordPress Developer</option>
                                                <option value="1671">PHP Developer Trainee</option>
                                                <option value="1672">Python Developer</option>
                                                <option value="1673">Cloud Engineer</option>
                                                <option value="1674">Cloud Computing</option>
                                                <option value="1675">Cloud Sales</option>
                                                <option value="1676">Cloud Testing</option>
                                                <option value="1677">Cloud Security</option>
                                                <option value="1678">Cloud Storage</option>
                                                <option value="1679">Cloud Consultant</option>
                                                <option value="1680">Cloud Administrator</option>
                                                <option value="1681">Cloud Architect</option>
                                                <option value="1682">Senior Cloud Engineer</option>
                                                <option value="1683">VMware Administrator</option>
                                                <option value="1684">VMware System Administrator</option>
                                                <option value="1685">VMware Sphere</option>
                                                <option value="1686">VMware Esx</option>
                                                <option value="1687">Windows VMware Administrator</option>
                                                <option value="1688">VMware Server Administrator</option>
                                                <option value="1689">VMware Engineer</option>
                                                <option value="1690">VMware Esxserver</option>
                                                <option value="1691">Windows CE.NET</option>
                                                <option value="1692">Microsoft Visual Studio .NET</option>
                                                <option value="1693">Microsoft Visual Studio 2005</option>
                                                <option value="1694">Microsoft Visual C++</option>
                                                <option value="1695">Microsoft Visual Basic</option>
                                                <option value="1696">Borland Delphi With .NET</option>
                                                <option value="1697">COM Interoperability</option>
                                                <option value="1698">.NET Compact Framework</option>
                                                <option value="1699">Visual Basic.NET</option>
                                                <option value="1700">Silverlight .NET</option>
                                                <option value="1701">ASP.NET, ADO.NET</option>
                                                <option value="1702">AJAX, JavaScript</option>
                                                <option value="1703">IIS Runtime Parameter Optimizations</option>
                                                <option value="1704">HTML, DHTML, XML, XSLT, CSS</option>
                                                <option value="1705">C#, Visual Basic.NET</option>
                                                <option value="1706">ADO.NET</option>
                                                <option value="1707">Microsoft Message Queuing Service</option>
                                                <option value="1708">COM+ Services</option>
                                                <option value="1709">Apache Camel</option>
                                                <option value="1710">Apache Tomcat</option>
                                                <option value="1711">Apache Web Server</option>
                                                <option value="1712">Apache Pig</option>
                                                <option value="1713">Apache Cordova</option>
                                                <option value="1714">Apache Server</option>
                                                <option value="1715">Weblogic Administrator</option>
                                                <option value="1716">Oracle Apps Technical</option>
                                                <option value="1717">PLSQL Developer</option>
                                                <option value="1718">Oracle Database Administrator</option>
                                                <option value="1719">Oracle Financial Functional Consultant</option>
                                                <option value="1720">Oracle Technical Consultant</option>
                                                <option value="1721">Oracle Financials</option>
                                                <option value="1722">Oracle Functional Consultant</option>
                                                <option value="1738">Advertising Consultant</option>
                                                <option value="1739">Broker</option>
                                                <option value="1740">Campus Representative</option>
                                                <option value="1741">Customer Service Advisor</option>
                                                <option value="1742">Inside Sales Representative</option>
                                                <option value="1743">Insurance Sales Agent</option>
                                                <option value="1744">Relationship Banker</option>
                                                <option value="1745">Travel Agent</option>
                                                <option value="1746">B2B Sales Representative</option>
                                                <option value="1747">Business Banker</option>
                                                <option value="1748">Client Associate</option>
                                                <option value="1749">Financial Representative</option>
                                                <option value="1750">Insurance Agent</option>
                                                <option value="1751">Insurance Professional</option>
                                                <option value="1752">Phone Banker</option>
                                                <option value="1753">Sales Representative</option>
                                                <option value="1754">Travel Counselor</option>
                                                <option value="1755">Brand Ambassador</option>
                                                <option value="1756">Business Development Representative</option>
                                                <option value="1757">Client Representative</option>
                                                <option value="1758">Financial Services Representative</option>
                                                <option value="1759">Insurance Consultant</option>
                                                <option value="1760">Insurance Representative</option>
                                                <option value="1761">Professional Services Consultant</option>
                                                <option value="1762">Service Agent</option>
                                                <option value="1763">Travel Specialist</option>
                                                <option value="1764">Clerk</option>
                                                <option value="1765">Customer Service Representative</option>
                                                <option value="1766">Retail Clerk</option>
                                                <option value="1767">Retail Sales Consultant</option>
                                                <option value="1768">Sales Clerk</option>
                                                <option value="1769">Teller</option>
                                                <option value="1770">Retail Sales Associate</option>
                                                <option value="1771">Merchandising Assistant</option>
                                                <option value="1772">Customer Assistant</option>
                                                <option value="1773">Store Clerk</option>
                                                <option value="1774">Appointment Setter</option>
                                                <option value="1775">Field Representative</option>
                                                <option value="1776">Promotional Model</option>
                                                <option value="1777">Real Estate Sales Representative</option>
                                                <option value="1778">Auctioneer</option>
                                                <option value="1779">Leasing Agent</option>
                                                <option value="1780">Realtor</option>
                                                <option value="1781">Demonstrator</option>
                                                <option value="1782">Leasing Consultant</option>
                                                <option value="1783">Domestic BPO</option>
                                                <option value="1784">KPO</option>
                                                <option value="1785">KTES</option>
                                                <option value="1786">International BPO</option>
                                                <option value="1787">BPO Operations</option>
                                                <option value="1788">BPO Analyst</option>
                                                <option value="1789">Semi Voice</option>
                                                <option value="1790">USA Process</option>
                                                <option value="1791">International Call Center</option>
                                                <option value="1792">Inbound Process</option>
                                                <option value="1793">Chemical Engineer</option>
                                                <option value="1794">Chemist</option>
                                                <option value="1795">QC Chemist</option>
                                                <option value="1796">Production Chemist</option>
                                                <option value="1797">Analytical Chemist</option>
                                                <option value="1798">Laboratory Chemist</option>
                                            </select><span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="14"><span className="selection"><span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-labelledby="select2-job_category-container"><span className="select2-selection__rendered" id="select2-job_category-container" role="textbox" aria-readonly="true"><span className="select2-selection__placeholder">Select Job Function</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10 selectdesign">
                                            <label className="font-sm color-text-mutted mb-5">Employment Type</label>
                                            <select name="employment_type" id="employment_type" className="form-control website-input" required="">
                                                <option value="Full-time">Full-time</option>
                                                <option value="Part-time">Part-time</option>
                                                <option value="Contract">Contract</option>
                                                <option value="Temporary">Temporary</option>
                                                <option value="Volunteer">Volunteer</option>
                                                <option value="Internship">Internship</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <div className="form-group mb-10">
                                            <label className="font-sm color-text-mutted mb-5">Seniority level</label>
                                            <select name="seniority_level" id="seniority_level" className="form-control website-input" required="">
                                                <option value="Internship">Internship</option>
                                                <option value="Entry level">Entry level</option>
                                                <option value="Associate">Associate</option>
                                                <option value="Mid-Senior level">Mid-Senior level</option>
                                                <option value="Director">Director</option>
                                                <option value="Executive">Executive</option>
                                                <option value="Not Applicable">Not Applicable</option>
                                            </select>
                                        </div>
                                        </div>
                                        <div className="col-lg-12">
                                        <div className="form-group mb-10"> 
                                            <label className="font-sm color-text-mutted mb-5">Job Description:</label>
                                            <textarea className="form-control" rows="3"  placeholder="Objective ">
                                                Attain the position of 'Java Developer' to implement my exceptional software development skills using new and improved technological tools and techniques for the development of the organization.
                                            </textarea>
                                        </div>
                                        </div>
                                        <div className="col-lg-12">
                                        <div className="form-group mt-10 mb-0">
                                            <button className="btn btn-default btn-brand icon-tick">Submit</button>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Add_post_jobs;
import React, { useState } from 'react';
import ReactTable from 'react-table';
//import 'react-table/react-table.css';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';

const TableWithPaginationAndExport = () => {
  const [tableData, setTableData] = useState([
    { id: 1, name: 'John Doe', age: 28 },
    { id: 2, name: 'Jane Smith', age: 32 },
    { id: 3, name: 'Bob Johnson', age: 45 },
    { id: 4, name: 'Alice Williams', age: 37 },
    { id: 5, name: 'Mike Brown', age: 50 },
    { id: 6, name: 'Sarah Davis', age: 29 },
    // ... more data
  ]);

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Age', accessor: 'age' },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 2;
  const pageCount = Math.ceil(tableData.length / itemsPerPage);
  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: 'Name', key: 'name' },
    { label: 'Age', key: 'age' },
  ];

  const csvData = tableData.map(({ id, name, age }) => ({
    id,
    name,
    age,
  }));

  return (
    <div>
      <ReactTable
        data={tableData.slice(
          currentPage * itemsPerPage,
          (currentPage + 1) * itemsPerPage
        )}
        columns={columns}
        defaultPageSize={itemsPerPage}
        className="-striped -highlight"
      />
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next"
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onPageChange}
        containerClassName="pagination"
        activeClassName="active"
      />
      <CSVLink data={csvData} headers={csvHeaders} filename="table_data.csv">
        Export to CSV
      </CSVLink>
    </div>
  );
};

export default TableWithPaginationAndExport;
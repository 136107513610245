import React , {useEffect}  from 'react';
//import LeftSidebar from '../../backend/include/left_sidebar';
//import RightSidebar from '../../backend/include/right_sidebar';

import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Blogs = () => {

    useEffect(() => {
      window.scrollTo(0, 0)
  }, [])
  return (
        <>
      <PageTopHeaderSec  pageTitle='Blogs' PageLink='blogs'   />
       
      <section class="section-box mt-50">
        <div class="container">
          <div class="box-improve">
            <div class="row">
              <div class="col-lg-5 col-md-12 col-sm-12"><a href="blog-detail"><img src="front/assets/imgs/blogs/img2.png" alt="jcbapp"/></a></div>
              <div class="col-lg-7 col-md-12 col-sm-12">
                <div class="pt-40 pb-30 pl-30 pr-30"><a class="btn btn-tag" href="blog-grid.html">Marketing</a>
                  <h2 class="mt-20 mb-20"><a href="blog-detail">Improve Your Business With These 8 Simple Tricks</a></h2>
                  <p class="font-md mb-20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam laoreet rutrum quam, id faucibus erat interdum a. Curabitur eget tortor a nulla interdum semper.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam laoreet rutrum quam, id faucibus erat interdum a. Curabitur eget tortor a nulla interdum semper.</p>
                  <div><a class="btn btn-arrow-right" href="blog-detail">Read More</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-box mt-50">
        <div class="post-loop-grid">
          <div class="container">
            <div class="text-left">
              <h2 class="section-title mb-10">Latest Posts</h2>
              <p class="font-lg color-text-paragraph-2">Don't miss the trending news</p>
            </div>
            <div class="row mt-30">
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">News</a></div>
                        <h5><a href="blog-detail">21 Job Interview Tips: How To Make a Great Impression</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user1.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">Email Examples: How To Respond to Employer Interview Requests</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user2.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">How To Write an Application Letter (With Examples)</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user3.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">17 Jobs That Hire at Age 15 (and Even 14)</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user1.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">How To Write a Cover Letter (Plus Tips and Examples)</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user2.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">10 Best Skills To Include on a Resume</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user3.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">39 Strengths and Weaknesses To Discuss in a Job Interview</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user1.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-30">
                    <div class="card-grid-3 hover-up">
                      <div class="text-center card-grid-3-image"><a href="blog-detail">
                          <figure><img alt="jcbapp" src="front/assets/imgs/blogs/img2.png"/></figure></a></div>
                      <div class="card-block-info">
                        <div class="tags mb-15"><a class="btn btn-tag" href="blog-grid.html">Events</a></div>
                        <h5><a href="blog-detail">List of Weaknesses: 10 Things To Say in an Interview</a></h5>
                        <p class="mt-10 color-text-paragraph font-sm">Our mission is to create the world&amp;rsquo;s most sustainable healthcare company by creating high-quality healthcare products in iconic, sustainable packaging.</p>
                        <div class="card-2-bottom mt-20">
                          <div class="row">
                            <div class="col-lg-6 col-6">
                              <div class="d-flex"><img class="img-rounded" src="front/assets/imgs/blogs/user1.png"/>
                                <div class="info-right-img"><span class="font-sm font-bold color-brand-1 op-70">Azumi Rose</span><br></br><span class="font-xs color-text-paragraph-2">25 April 2022</span></div>
                              </div>
                            </div>
                            <div class="col-lg-6 text-end col-6 pt-15"><span class="color-text-paragraph-2 font-xs">8 mins to read</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
             </div>
                <div class="paginations">
                  <ul class="pager">
                    <li><a class="pager-prev" href="#"></a></li>
                    <li><a class="pager-number" href="#">1</a></li>
                    <li><a class="pager-number" href="#">2</a></li>
                    <li><a class="pager-number" href="#">3</a></li>
                    <li><a class="pager-number" href="#">4</a></li>
                    <li><a class="pager-number" href="#">5</a></li>
                    <li><a class="pager-number active" href="#">6</a></li>
                    <li><a class="pager-number" href="#">7</a></li>
                    <li><a class="pager-next" href="#"></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 col-12 pl-lg-15 mt-lg-30">
                <div class="widget_search mb-20">
                  <div class="search-form">
                    <form action="#">
                      <input type="text" placeholder="Search…" />
                      <button type="submit"><i class="fi-rr-search"></i></button>
                    </form>
                  </div>
                </div>
                <div class="sidebar-shadow sidebar-news-small">
                  <h5 class="sidebar-title">Trending Now</h5>
                  <div class="post-list-small">
                    <div class="post-list-small-item d-flex align-items-center">
                      <figure class="thumb mr-15"><img src="front/assets/imgs/blogs/img2.png" alt="jcbapp" /></figure>
                      <div class="content">
                        <h5>How to get better agents in New York, USA</h5>
                        <div class="post-meta text-muted d-flex align-items-center mb-15">
                          <div class="author d-flex align-items-center mr-20"><img alt="jcbapp" src="front/assets/imgs/blogs/user1.png"/><span>Sugar Rosie</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="post-list-small-item d-flex align-items-center">
                      <figure class="thumb mr-15"><img src="front/assets/imgs/blogs/img2.png" alt=""/></figure>
                      <div class="content">
                        <h5>How To Create a Resume for a Job in Social</h5>
                        <div class="post-meta text-muted d-flex align-items-center mb-15">
                          <div class="author d-flex align-items-center mr-20"><img alt="jcbapp" src="front/assets/imgs/blogs/user3.png"/><span>Harding</span></div>
                          <div class="date"><span>17 Sep</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="post-list-small-item d-flex align-items-center">
                      <figure class="thumb mr-15"><img src="front/assets/imgs/blogs/img2.png" alt=""/></figure>
                      <div class="content">
                        <h5>10 Ways to Avoid a Referee Disaster Zone</h5>
                        <div class="post-meta text-muted d-flex align-items-center mb-15">
                          <div class="author d-flex align-items-center mr-20"><img alt="jcbapp" src="front/assets/imgs/blogs/user2.png"/><span>Steven</span></div>
                          <div class="date"><span>23 Sep</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="post-list-small-item d-flex align-items-center">
                      <figure class="thumb mr-15"><img src="front/assets/imgs/blogs/img2.png" alt=""/></figure>
                      <div class="content">
                        <h5>How To Set Work-Life Boundaries From Any Location</h5>
                        <div class="post-meta text-muted d-flex align-items-center mb-15">
                          <div class="author d-flex align-items-center mr-20"><img alt="jcbapp" src="front/assets/imgs/blogs/user3.png"/><span>Merias</span></div>
                          <div class="date"><span>14 Sep</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="post-list-small-item d-flex align-items-center">
                      <figure class="thumb mr-15"><img src="front/assets/imgs/blogs/img2.png" alt=""/></figure>
                      <div class="content">
                        <h5>How to Land Your Dream Marketing Job</h5>
                        <div class="post-meta text-muted d-flex align-items-center mb-15">
                          <div class="author d-flex align-items-center mr-20"><img alt="jcbapp" src="front/assets/imgs/blogs/user1.png"/><span>Rosie</span></div>
                          <div class="date"><span>12 Sep</span></div>
                        </div>
                      </div>
                 </div>
                 </div>
                </div>
                
               
              </div>
            </div>
          </div>
        </div>
      </section>
     
    
      <SubscribeModule />
        </>
    );
  };
  export default Blogs;
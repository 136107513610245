import React from "react";
const OurSolutions = () => {
    return(
        <section className="section-box overflow-visible pt-100 pb-100 oursolution">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
                <h6 className="headtop clr-primary mb-10">OUR SOLUTIONS</h6>
                <h2 className="section-title mb-40">This is what makes us different! </h2>   
                <p>Our solutions provide a secure platform to connect job seekers and employers with the best fit for both parties. Furthermore, it provides employers with an opportunity to showcase their talents and highlight the unique skills and experience they bring to the table. </p>
   

            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-6">
                <img src="front/assets/imgs/solution.png" />            
            </div>
            
          </div>
          
        </div>
      </section>
        );
}

export default OurSolutions;
import React from "react";

class Pagenot_found extends React.Component {
    render() {
        return(
            <>
            <section className="section-box pt-50 pb-50">
   <div className="post-loop-grid">
      <div className="container">
         <div className="text-center ">
            <div className="text-center thankyouimage"><img src="front/assets/imgs/404.png" /></div>
            <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">Page Not Found</h2>
           <div class="mt-3"><a class="btn btn-default btn-shadowhover-up" href="/">Back to home</a></div>
         </div>
      </div>
   </div>
</section>
            </>
        );
    }
}
export default Pagenot_found;
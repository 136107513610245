import React from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

const Cerate_recruiter = () => {
    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-md-3">
               <LeftSidebar />
              </div>
              <div className="col-md-9">
              <div className="section-box jobpost-sec">
                     <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                           <h5>Recruiters</h5>
                           <a data-bs-toggle="modal" data-bs-target="#createrecuruter" className="btn btn-default btn-small ml-auto">+ Add Recruiter</a>
                        </div>
                        <div className="table-responsive resume_table recurutertbl">
                           <table className="table table-striped" >
                              <thead>
                                 <tr role="row">
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Password</th>
                                    <th className="recruterstatus">Status</th>
                                    
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr role="row" className="odd">
                                    <td>Vipul Reddy</td>
                                    <td>reddyvipul9797@gmail.com</td>
                                    <td>9010965224</td>
                                    <td>PD8AT7N8</td>
                                    <td>
                                       <span className="mr-5" >Enabled</span>
                                       
                                       <a href="#" className="ml-10 roundtab">Make It Disable</a>
                                    </td>
                                 </tr>
                                 <tr role="row" className="odd">
                                    <td>shiva</td>
                                    <td>kumarshiva881@gmail.com</td>
                                    <td>895656666</td>
                                    <td>6EPXA5FX</td>
                                    <td>
                                       <span className="mr-5" >Enabled</span>
                                       
                                       <a href="#" className="ml-10 roundtab">Make It Disable</a>
                                    </td>
                                 </tr>
                                 <tr role="row" className="odd">
                                    <td>David</td>
                                    <td>david@gmail.com</td>
                                    <td>4561284536</td>
                                    <td>HHRTNJ7N</td>
                                    <td>
                                       <span className="mr-5" >Enabled</span>
                                       
                                       <a href="#" className="ml-10 roundtab">Make It Disable</a>
                                    </td>
                                 </tr>
                                 <tr role="row" className="odd">
                                    <td>Vishal</td>
                                    <td>vishal@gmail.com</td>
                                    <td>7845129965</td>
                                    <td>94LSRG21</td>
                                    <td>
                                       <span className="mr-5" >Enabled</span>
                                       
                                       <a href="#" className="ml-10 roundtab">Make It Disable</a>
                                    </td>
                                 </tr>
                                 <tr role="row" className="odd">
                                    <td>Sagar</td>
                                    <td>sagar@gmail.com</td>
                                    <td>5555555555</td>
                                    <td>839376UQ</td>
                                    <td>
                                       <span className="mr-5" >Enabled</span>
                                       
                                       <a href="#" className="ml-10 roundtab">Make It Disable</a>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
          </div>
        </div>


        <div className="modal fade" id="createrecuruter" tabindex="-1" aria-hidden="true">
         <div className="modal-dialog modal-lg">
            <div className="modal-content apply-job-form">
               <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
               <div className="modal-body pl-30 pr-30 pt-50">
                  <div className="text-center">
                     <p className="font-sm text-brand-2">Create Recruiter </p>
                     <h3 className="mt-10 mb-5 text-brand-1 text-capitalize">Start with your new Recruiter</h3>
                     <p className="font-sm text-muted mb-30">Please fill in below information and create Recruiter.</p>
                  </div>
                  <form className="login-register addnoteform text-start mt-20 pb-30" action="#">
                     <div className="form-group">
                        <label className="form-label">Name</label>
                        <input className="form-control"  type="text" required="" name="fullname" placeholder="Enter Recruiter Name" />
                     </div>
                     <div className="form-group">
                        <label className="form-label">Email</label>
                        <input className="form-control"  type="text" required="" name="fullname" placeholder="Enter Recruiter Email" />
                     </div>
                     <div className="form-group">
                        <label className="form-label">Password</label>
                        <div className="d-flex generatepasswrap">
                           <input className="form-control"  type="text" required="" name="fullname" placeholder="Enter Recruiter Email" />
                           <a className="btn btn-default ml-20" href="#">Generate</a>
                        </div>
                        
                     </div>
                     <div className="form-group">
                        <label className="form-label">Mobile Number:</label>
                        <input className="form-control"  type="text" required="" name="fullname" placeholder="Enter Mobile Number:" />
                     </div>
                     
                   
                     <div className="form-group">
                        <button className="btn btn-default hover-up w-100" type="submit"  >Create Recruiter</button>
                     </div>
                     <div className="text-muted text-center">Do you need support? <a href="#">Contact Us</a></div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      </>
    );
  };
export default Cerate_recruiter;
import React from 'react';

import SubscribeModule from './template_parts/SubscribeModule';
import JobSeekerPageBody from './template_parts/JobSeekerPageBody';


const Recruiters = () => {
    return (
      <>
        <JobSeekerPageBody />
        <SubscribeModule />
      </>
    );
  };
export default Recruiters;
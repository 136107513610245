import React from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';

const Selected_resumes = () => {
    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
                  <div className="section-box jobpost-sec">
                     <div className="container">
                        <div className="head-border mb-30 d-flex justify-content-between align-items-center">
                           <h5>Seleted Resumes</h5>
                        </div>
                        <div className="table-responsive resume_table">
                           <table className="table table-striped">
                              <thead>
                                 <tr>
                                    <th >S.No</th>
                                    <th>Position Applied</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile Number</th>
                                    <th>Move To</th>
                                    <th>Posted Date</th>
                                    <th>Action</th>
                                 </tr>
                                 <tr>
                                    <td><p>1</p></td>
                                    <td><p>Subbu</p></td>
                                    <td><p>Samuel Sagar</p></td>
                                    <td><p> jobseeker@gmail.com</p></td>
                                    <td><p>1230456789</p></td>
                                    <td>
                                       <select className="form-control login-ctrl moveToFolder">
                                          <option value="">Select Folder</option>
                                          <option value="89_40">Aasha</option>
                                          <option value="89_39">Subbu</option>
                                          <option value="89_37">Vinod</option>
                                          <option value="89_35">W3 Global</option>
                                          <option value="89_31">AI</option>
                                          <option value="89_27">Java Developer</option>
                                       </select>
                                    </td>
                                    <td><p>14-12-2022</p></td>
                                    <td>
                                       <p>
                                          <a href="#"><i className="fa fa-download mr-5" aria-hidden="true"></i></a>
                                          <a href="#"><i className="fa fa-trash" aria-hidden="true"></i></a>
                                       </p>
                                    </td>
                                 </tr>
                              </thead>
                              <tbody>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Selected_resumes;
import React  from 'react';
import LeftSidebar from '../../backend/include/left_sidebar';
import RightSidebar from '../../backend/include/right_sidebar';

const NotFound_404 = () => {
    var storedNames = JSON.parse(localStorage.getItem("userlogin"));
    return (
        <>
        <div className="dashboardsec pt-70 pb-70">
            <div className="container">
                <div className="row">
 
                    {
                        storedNames ? 
                        <>
                            <div className="col-md-3">
                                <LeftSidebar />
                            </div>
                            <div className="col-md-6">
                                <h5 className='text-center' >404 page not found</h5>
                            </div>
                            <div className="col-md-3">
                                <RightSidebar />
                            </div>
                        </>
                        :
                        <>
                            <div className="col-md-12">
                            <h5 className='text-center' >404 page not found</h5>
                            </div>
                        </>
                    }
                    
                </div>
            </div>
        </div>
        </>
    );
  };
export default NotFound_404;
import React  from 'react';
import { Link } from "react-router-dom";

const PageTopHeaderSec = (prop) => {
    return (
        <>
     
        <section className="section-box" id="top">
            <div className="breacrumb-cover bg-img-about">
            <div className="container">
                <div className="row">
                <div className="col-lg-6">
                    <h2 className="mb-10">{prop.pageTitle ? prop.pageTitle : '' }</h2>
                    <p className="font-lg color-text-paragraph-2">Get the latest news, updates and tips</p>
                </div>
                <div className="col-lg-6 text-lg-end">
                    <ul className="breadcrumbs mt-40">
                    <li><Link className="home-icon" to="/">Home</Link></li>
                    <li>{prop.pageTitle ? prop.pageTitle : '' }</li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </section>
        </>
    );
  };
export default PageTopHeaderSec;
import React , {useEffect}  from 'react';
import SubscribeModule from './template_parts/SubscribeModule';
import PageTopHeaderSec from './template_parts/PageTopHeaderSec';

const PrivacyPolicy  = () => {

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

    return (
        <>
      <PageTopHeaderSec pageTitle='Privacy Policy' PageLink='privacy-policy'  />
      
         <>
         <section className="privacypolicysec">
         <div className="container">
            <div className="">
                <p>Jobchatbox collects, discloses, uses, retains, and otherwise manages your personal information in accordance with the terms of this Privacy Collection Statement. The term "Consulting Companies" in this privacy collection statement refers to the services offered by Career Hamster.</p>
                <h4>1. Protecting your privacy</h4>
                <p>Here at Jobchatbox, we respect your right to a private life. We will always take the utmost care to protect your confidentiality. Career Hamster complies with all applicable privacy laws, including the 13 Australian Privacy Principles (APPs) in the Privacy Act 1988 (Privacy Act) as well as our existing obligations of confidentiality. The methods by which we gather data, including personal data, and the means by which we store, utilize, and make available that data are detailed in this Privacy Collection Statement. In addition to outlining our general rights and obligations in regards to the information we maintain on file, it also provides some insight on your privacy rights.</p>
                <h4>2. Types of information that we collect and hold </h4>
                <p>Depending on how we feel about you, we may collect and store different types of information. These connections are typically classified as: • Candidates - encompass all individuals who contact us or who are employed by us to undertake services for us or our clients; • Clients - encompass all parties with whom we conduct business in order to provide them with recruitment services and solutions for their business; • Referees - include each person we get in touch with while assessing the qualifications and employment readiness of our candidates; and • Participants – include everyone who receives coaching, change programs, job transition services, career management services, or any other services from our Consulting Companies.</p>
                <h5>2.1 candidates</h5>
                <p>If you contact us by another method, such as a phone call or an email, we may still gather personal information from you even if you don't fit into one of the aforementioned categories.</p>
                <p>If you are a candidate we collect your personal information as reasonably necessary for us to determine your suitability for work with us or through us.</p>
                <p><strong>The main types of personal information we collect about you as a candidate are:</strong></p>
                <ul>
                    <li>Your name and contact details, including your address, email address and phone numbers;</li>
                    <li>Your date of birth;</li>
                    <li>Your gender;</li>
                    <li>information in your cover letter, resume and application forms including your skills, qualifications, work history, references, goals and interests;</li>
                    <li>Details of your work rights in Australia and other countries;</li>
                    <li>Your tax file number and superannuation details;</li>
                    <li>Information documenting your work history with or through us (including bank account details, salary, work performance information and salary sacrifice documents);</li>
                    <li>Aptitude and psychological assessment results;</li>
                    <li>The results of police checks, working with children checks or other background checks;</li>
                    <li>Medical reviews or assessments of you; and</li>
                    <li>other information that you, your referees or our clients provide to us, including personal feedback and notes of our interactions with you and/or others in relation to your suitability for work with us or through us.</li>
                </ul>   
                <p><strong>Personal information falls into several categories, however sensitive data falls into a separate category on its own. There may be facts or judgments included about you, such as:</strong></p>
                <ul>
                   <li>Racial or ethnic origin;</li>
                    <li>Political opinion;</li>
                    <li>Membership of a political association or religious beliefs, affiliations or philosophical beliefs;</li>
                    <li>Membership of a professional or trade association or membership of a trade union; </li>
                    <li>Sexual preferences or practices;</li>
                    <li>Criminal record;</li>
                    <li>Health or disability (at any time); and</li>
                    <li>expressed wishes about the future provision of health services.</li>                
                    </ul>
                    <p>Except where absolutely necessary for our business operations, we do not deliberately seek to gather sensitive information. If necessary, we will only gather sensitive data in compliance with the APPs. In most circumstances, sensitive information won't be shared without your permission.</p>

                    <h5>2.2 clients</h5>
                    <p>If you are our client we collect information that is reasonably necessary for us to provide the best recruitment solutions for you and your business needs. The main types of information we collect about you as our client are:</p>
                    <ul>
                    <li>Your contact details including your address and telephone numbers;</li>
                    <li>Details of your job title/description and organisational needs; and</li>
                    <li>Records of our interaction with you and confidential feedback you may give us regarding our candidates.</li>
                    </ul>

                    <h5>2.3 Referees</h5>
                    <p>If you are a referee we collect information that is reasonably necessary for us to determine a candidate’s suitability for work with us or through us. The main types of information we collect about referees are:</p>
                    <ul>
                    <li>Your contact details including your address and telephone numbers; and</li>
                    <li>Details of your job title/description; and</li>
                    <li>Your confidential opinions about a candidate and their suitability for work with or through us.  </li> 
                     </ul>

                     <h5>2.4 Participants </h5>
                     <p>If you are a participant we collect information that is reasonably necessary for us to provide you career transition services, career management services, change programs, coaching or other services provided by our Consulting Companies. The main types of personal information we collect about you are similar to the information we collect about candidates (outlined in section 2.1).</p>

                     <h4>3. who will be collecting your personal and sensitive information</h4>
                      <p>Your personal and sensitive information will be collected by Jobchatbox for its own use and on behalf of other members of the Jobchatbox, who might require access to your personal and sensitive information in connection with its recruitment services. Each time you visit our web site statistical data is collected. This data is not linked to a particular person and does not provide us with any personal information.</p>

                      <h5>4.1 candidates </h5>
                      <p>Personal and sensitive information will be collected from you directly when you attend an interview with one of our staff members. At this time you will complete our registration paperwork or any other information in connection with your application to us for registration. Personal and sensitive information will also be collected when We receive any reference about you;</p>
                      <ul>
                         <li>We receive results of inquiries that we might make with your former employers, work colleagues, professional associations or registration body;</li>
                        <li>We receive the results of your work rights status;</li>
                        <li>We receive the results of any competency or medical test;</li>
                        <li>We receive performance feedback (whether positive or negative);</li>
                        <li>We receive any complaint from or about you in the workplace;</li>
                        <li>We receive any information about a workplace accident in which you are involved;</li>
                        <li>We receive a response from a job advertisement – either written, verbal or email;</li>
                        <li>We receive any information about any insurance investigation, litigation, registration or professional disciplinary matter, criminal matter, inquest or inquiry in which you are involved; and </li>
                        <li>You provide us with any additional information about you. Sensitive information is a special category of personal information. It includes information or an opinion about you’re:</li>
                        <li>Racial or ethnic origin;</li>
                        <li>Political opinion;</li>
                        <li>Membership of a political association or religious beliefs, affiliations or philosophical beliefs;</li>
                        <li>Membership of a professional or trade association or membership of a trade union;</li> 
                        <li>Sexual preferences or practices;</li>
                        <li>Criminal record;</li>
                        <li>Health or disability (at any time); and</li>
                        <li>expressed wishes about the future provision of health services.</li>
                      </ul>

                      <h5>4.2 clients</h5>
                      <p><strong>If you are a client the ways in which we collect information about you are when:</strong></p>
                      <ul>
                      <li>We meet you or communicate with you in any way; or</li>
                      <li>When you provide your opinion or feedback regarding one of our candidates.</li>
                    </ul>

                    <h4>5 Use of your personal information</h4>
                    <h5>5.1 candidates</h5>
                    <p><strong>Your personal and sensitive information may be used in connection with:</strong></p>
                    <ul>
                    <li>Your actual or possible work placement;</li>
                    <li>checking your work rights status with any government department or body (or their agents);</li>
                    <li>To undertake directly or through agent criminal reference checking with relevant government agencies;</li>
                    <li>Payment for work completed on a temporary/contract assignment;</li>
                    <li>follow up with you to offer you work or ascertain your availability for work;</li>
                    <li>Your performance appraisals;</li>
                    <li>Our assessment of your ongoing performance and prospects;</li>
                    <li>Any test or assessment (including medical tests and assessments) that you might be required to undergo;</li>
                    <li>Our identification of your training needs;</li>
                    <li>Any workplace rehabilitation;</li>
                    <li>Our management of any complaint, investigation or inquiry in which you are involved;</li>
                    <li>Any insurance claim or proposal that requires disclosure of your personal or sensitive information;</li>
                    <li>To tell you about the other services that we may provide; and</li>
                    <li>Ensuring our internal business processes are running smoothly which may include quality assurance audits, quality and services evaluations, fulfilling legal requirements and conducting confidential systems maintenance and testing. </li>
                    </ul>

                    <h5>5.2 clients</h5>

             <p><strong>Personal information that we collect, hold, use and disclose about clients is typically used for:</strong></p>
            <ul>
            <li>Client and business relationship management;</li>
            <li>Recruitment functions;</li>
            <li>marketing services to you;</li>
            </ul>
            </div>
         </div>
      </section>
         </>

      <SubscribeModule />
        </>
    );
  };
export default PrivacyPolicy;
import React , { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
const PostLoadingContent = () => {
    const navigate = useNavigate();
    useEffect(() => {
        setTimeout(function() {
            navigate('/dashboard');
         }, 500);
    }, []);
    return (
        <>
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 padding5percent">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};
export default PostLoadingContent;
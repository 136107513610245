import React, { useState, useEffect } from 'react';
import axios from 'axios';

function InfiniteScroll() {
  const [items, setItems] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    loadMoreItems();
  }, [lastIndex]);

  function handleScroll() {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !isLoading) {
      loadMoreItems();
    }
  }

  function loadMoreItems() {
    setIsLoading(true);
    axios.get(`http://example.com/api/items?startIndex=${lastIndex}&count=10`)
      .then(response => {
        const newItems = [...items, ...response.data];
        setItems(newItems);
        setLastIndex(newItems.length);
        setIsLoading(false);
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  }

  return (
    <div>
      {items.slice(0, lastIndex).map(item => (
        <div key={item.id}>{item.name}</div>
      ))}
      {isLoading && <div>Loading...</div>}
    </div>
  );
}

export default InfiniteScroll;
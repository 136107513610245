import React from 'react';

import JobsPageHeader from './template_parts/JobsPageHeader';
import SubscribeModule from './template_parts/SubscribeModule';
import JobsPageBody from './template_parts/JobsPageBody';
const Jobs = () => {
    return (
      <>
        <JobsPageBody />
        <SubscribeModule />
      </>
    );
  };
export default Jobs;
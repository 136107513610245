import React from "react";
import { Nav, NavLink, NavMenu } from "../components/NavbarElements";
import {useNavigate} from 'react-router-dom';

import JobseekerSignup from './forms_temp/jobseeker_signup';
import RecruiterSignup from './forms_temp/recruiter_signup';



//console.log(API_ENDPOINT);
class RegistrationFrom2 extends React.Component {

 
    render() {
        return(
            <>
                <section className="pt-30 login-register">
                    <div className="container"> 
                        <div className="row login-register-cover pb-70">
                
                        <div className="col-lg-2 col-md-2 col-sm-12">
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 registrationcol">
                        <div className="formwraper">
                            <div className="text-center">
                                <p className="font-sm text-brand-2">Register </p>
                                <h3 className="mt-10 mb-5 text-brand-1">Start for free Today</h3>
                                
                                {/* <button className="btn social-login hover-up mb-20">
                                <img src="front/assets/imgs/icon-google.svg" alt="jcbapp" /><strong>Sign up with Google</strong></button>
                                <div className="divider-text-center"><span>Or continue with</span></div> */}
                            </div>
                        <div className="list-tabs registrationtab">
                            <ul className="nav nav-tabs" role="tablist">
                            <li><a className="active" id="nav-tab-job-1" href="#tab-job-1" data-bs-toggle="tab" role="tab" aria-controls="tab-job-1" aria-selected="true">Jobseeker</a></li>
                            <li><a id="nav-tab-job-2" href="#tab-job-2" data-bs-toggle="tab" role="tab" aria-controls="tab-job-2" aria-selected="false">Recruiter</a></li>
                            
                            </ul>
                        </div>

                        <div className="tab-content registrationtabcon" id="myTabContent-1">
                            <div className="tab-pane fade  show active" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                                <JobseekerSignup />
                            </div>
                            <div className="tab-pane fade" id="tab-job-2" role="tabpanel" aria-labelledby="tab-job-2" >
                                <RecruiterSignup />
                            </div>
                            <div className="col-md-12">
                                <div className="text-muted text-center">Already have an account? 
                                    <NavLink  to="/login"  > Sign in</NavLink> 
                                </div>
                            </div>
                        </div>


                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-12">
                        </div>
                    
                        
                        </div>
                    </div>
                </section>    
            </>
        );
    }


}

export default RegistrationFrom2;
import React from 'react';

//TopBanner
import TopBanner from './template_parts/TopBanner';
import HomeServices from './template_parts/HomeServices';
import WhyChooseUs from './template_parts/WhyChooseUs';
import OurSolutions from './template_parts/OurSolutions';
import HappyCustomer from './template_parts/HappyCustomer';
import PricingPlans from './template_parts/PricingPlans';
import LetsTalk from './template_parts/LetsTalk';
import SubscribeModule from './template_parts/SubscribeModule';
  

const Home = () => {
    return (
      <>
      
            <TopBanner />
            <img  className="w-100" src="front/assets/imgs/bannershap.png" />
            <HomeServices />
            <WhyChooseUs />
            <OurSolutions /> 
            <HappyCustomer />
            <PricingPlans />
            <LetsTalk />
            <div className="homesubscribe"><SubscribeModule /></div>
      </>
    );
  };
export default Home;
import React from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

const Email_templates = () => {
    return (
      <>
          <div className="dashboardsec pt-30 pb-30">
           <div className="container">
            
            <div className="row">
              <div className="col-lg-3">
               <LeftSidebar />
              </div>
              <div className="col-lg-9">
              <div class="section-box jobpost-sec">
                     <div class="head-border mb-30 d-flex justify-content-between align-items-center">
                              <h5>Email Templates</h5>
                              
                           </div>

                     <ul class="nav nav-tabs" id="emailtabs" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="emailtab1" data-bs-toggle="tab" data-bs-target="#emailtab1con" type="button" role="tab" aria-controls="emailtab1con" aria-selected="true"><img src="front/assets/imgs/interview.png" />Arrange An Interview</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="emailtab2" data-bs-toggle="tab" data-bs-target="#emailtab2con" type="button" role="tab" aria-controls="emailtab2con" aria-selected="false"><img src="front/assets/imgs/call-shadule.png" />Schedule Phone Call</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="emailtab3" data-bs-toggle="tab" data-bs-target="#emailtab3con" type="button" role="tab" aria-controls="emailtab3con" aria-selected="false"><img src="front/assets/imgs/skype.png" />Skype Interview</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="emailtab4" data-bs-toggle="tab" data-bs-target="#emailtab4con" type="button" role="tab" aria-controls="emailtab4con" aria-selected="false"><img src="front/assets/imgs/facetoface.png" />Face To Face Interview</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="emailtab5" data-bs-toggle="tab" data-bs-target="#emailtab5con" type="button" role="tab" aria-controls="emailtab5con" aria-selected="false"><img src="front/assets/imgs/rejected.png" />Rejected</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="emailtab6" data-bs-toggle="tab" data-bs-target="#emailtab6con" type="button" role="tab" aria-controls="emailtab6con" aria-selected="false"><img src="front/assets/imgs/offer.png" />Offer Letter</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="emailtab7" data-bs-toggle="tab" data-bs-target="#emailtab7con" type="button" role="tab" aria-controls="emailtab7con" aria-selected="false"><img src="front/assets/imgs/hired.png" />Hired</button>
                          </li>
                        </ul>
                        <div class="tab-content" id="emailtabpanelwrap">
                          
                          <div class="tab-pane fade show active" id="emailtab1con" role="tabpanel" aria-labelledby="emailtab1">
                            
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                       
                          </div>
                          <div class="tab-pane fade" id="emailtab2con" role="tabpanel" aria-labelledby="emailtab2">
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                          </div>
                          <div class="tab-pane fade" id="emailtab3con" role="tabpanel" aria-labelledby="emailtab3">
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                          </div>
                          <div class="tab-pane fade" id="emailtab4con" role="tabpanel" aria-labelledby="emailtab4">
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                          </div>
                          <div class="tab-pane fade" id="emailtab5con" role="tabpanel" aria-labelledby="emailtab5">
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                          </div>
                          <div class="tab-pane fade" id="emailtab6con" role="tabpanel" aria-labelledby="emailtab6">
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                          </div>
                          <div class="tab-pane fade" id="emailtab7con" role="tabpanel" aria-labelledby="emailtab7">
                             <form class="emailtemplateform text-start mt-20 pb-30" action="#">
                                 <div class="form-group">
                                    <label class="form-label" for="input-1">Subject</label>
                                    <input class="form-control" id="input-1" type="text" placeholder="Please Enter Email Subject" />
                                 </div>
                                 <div class="form-group">
                                    <label class="form-label" for="input-2">Message</label>
                                    <textarea placeholder="Message"></textarea>
                                 </div>
                                 <div class="form-group d-flex ">
                                    <button class="btn btn-default hover-up mr-10" type="submit">Submit</button>
                                    <a href="#" class="btn btn-default hover-up" type="submit">Switch To Default Email Template</a>
                                 </div>
                              </form>
                             
                          </div>
                        </div>
                  </div>
               </div>

            </div>
          </div>
        </div>
      </>
    );
  };
export default Email_templates;
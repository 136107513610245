import React from 'react';

import SubscribeModule from './template_parts/SubscribeModule';
import RecruitersPageBody from './template_parts/RecruitersPageBody';


class Recruiters extends React.Component {
  render() {
    return (
        <>

          <RecruitersPageBody />
          <SubscribeModule />
        </>
    );
  }
}

export default Recruiters;
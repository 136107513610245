import React from 'react';
import LeftSidebar from '../include/left_sidebar';
import RightSidebar from '../include/right_sidebar';
import { Link } from "react-router-dom";

//TopBanner

const Group_members = () => {
    return (
      <>
        <div className="dashboardsec pt-30 pb-30">
            <div className="container">
               <div className="row">
                  <div className="col-lg-3">
                  <LeftSidebar />
                     
                  </div>
                  <div class="col-lg-9">
                     <div class="box-filters-job">
                        <div class="row">
                           <div class="col-xl-6 col-lg-5"><span class="text-small text-showing">Showing <strong>41-60 </strong>of <strong>944 </strong>jobs</span></div>
                           <div class="col-xl-6 col-lg-7 text-lg-end mt-sm-15">
                              <div class="display-flex2">
                                 <div class="box-border mr-10">
                                    <span class="text-sortby">Show:</span>
                                    <div class="dropdown dropdown-sort">
                                       <button class="btn dropdown-toggle" id="dropdownSort" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>12</span><i class="fi-rr-angle-small-down"></i></button>
                                       <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort">
                                          <li><a class="dropdown-item active" href="#">10</a></li>
                                          <li><a class="dropdown-item" href="#">12</a></li>
                                          <li><a class="dropdown-item" href="#">20</a></li>
                                       </ul>
                                    </div>
                                 </div>
                                 <div class="box-border">
                                    <span class="text-sortby">Sort by:</span>
                                    <div class="dropdown dropdown-sort">
                                       <button class="btn dropdown-toggle" id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static"><span>Location</span><i class="fi-rr-angle-small-down"></i></button>
                                       <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                          <li><a class="dropdown-item active" href="#">Location</a></li>
                                          <li><a class="dropdown-item" href="#">Location</a></li>
                                          <li><a class="dropdown-item" href="#">Location</a></li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="connectionbockwrap">
                        <div class="tab-content" id="myTabContent-1">
                           <div class="d-flex flex-wrap" id="tab-job-1" role="tabpanel" aria-labelledby="tab-job-1">
                              <div class="card-grid-2 connectionblock mb-5">
                                 <div class="card-grid-2-image-left">
                                    <div class="card-grid-2-image-rd">
                                       <a href="#">
                                          <figure><img alt="jcbapp" src="front/assets/imgs/user1.png" /></figure>
                                       </a>
                                    </div>
                                    <div class="card-profile">
                                       <a href="#">
                                          <h6> Alexander</h6>
                                       </a>
                                       <div class="font-xs color-text-mutted mb-5">UI/UX Designer</div>
                                       <div class="font-xs"><i class="fa fa-map-marker" aria-hidden="true"></i> Indore</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card-grid-2 connectionblock mb-5">
                                 <div class="card-grid-2-image-left">
                                    <div class="card-grid-2-image-rd">
                                       <a href="#">
                                          <figure><img alt="jcbapp" src="front/assets/imgs/user1.png" /></figure>
                                       </a>
                                    </div>
                                    <div class="card-profile">
                                       <a href="#">
                                          <h6> Alexander</h6>
                                       </a>
                                       <div class="font-xs color-text-mutted mb-5">UI/UX Designer</div>
                                       <div class="font-xs"><i class="fa fa-map-marker" aria-hidden="true"></i> Indore</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card-grid-2 connectionblock mb-5">
                                 <div class="card-grid-2-image-left">
                                    <div class="card-grid-2-image-rd">
                                       <a href="#">
                                          <figure><img alt="jcbapp" src="front/assets/imgs/user1.png" /></figure>
                                       </a>
                                    </div>
                                    <div class="card-profile">
                                       <a href="#">
                                          <h6> Alexander</h6>
                                       </a>
                                       <div class="font-xs color-text-mutted mb-5">UI/UX Designer</div>
                                       <div class="font-xs"><i class="fa fa-map-marker" aria-hidden="true"></i> Indore</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card-grid-2 connectionblock mb-5">
                                 <div class="card-grid-2-image-left">
                                    <div class="card-grid-2-image-rd">
                                       <a href="#">
                                          <figure><img alt="jcbapp" src="front/assets/imgs/user1.png" /></figure>
                                       </a>
                                    </div>
                                    <div class="card-profile">
                                       <a href="#">
                                          <h6> Alexander</h6>
                                       </a>
                                       <div class="font-xs color-text-mutted mb-5">UI/UX Designer</div>
                                       <div class="font-xs"><i class="fa fa-map-marker" aria-hidden="true"></i> Indore</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card-grid-2 connectionblock mb-5">
                                 <div class="card-grid-2-image-left">
                                    <div class="card-grid-2-image-rd">
                                       <a href="#">
                                          <figure><img alt="jcbapp" src="front/assets/imgs/user1.png" /></figure>
                                       </a>
                                    </div>
                                    <div class="card-profile">
                                       <a href="#">
                                          <h6> Alexander</h6>
                                       </a>
                                       <div class="font-xs color-text-mutted mb-5">UI/UX Designer</div>
                                       <div class="font-xs"><i class="fa fa-map-marker" aria-hidden="true"></i> Indore</div>
                                    </div>
                                 </div>
                              </div>
                              <div class="card-grid-2 connectionblock mb-5">
                                 <div class="card-grid-2-image-left">
                                    <div class="card-grid-2-image-rd">
                                       <a href="#">
                                          <figure><img alt="jcbapp" src="front/assets/imgs/user1.png" /></figure>
                                       </a>
                                    </div>
                                    <div class="card-profile">
                                       <a href="#">
                                          <h6> Alexander</h6>
                                       </a>
                                       <div class="font-xs color-text-mutted mb-5">UI/UX Designer</div>
                                       <div class="font-xs"><i class="fa fa-map-marker" aria-hidden="true"></i> Indore</div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
    );
  };
export default Group_members;
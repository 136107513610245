import React,{useState,useEffect} from "react";

//class ThankYou extends React.Component {
const ThankYou = () => {

      const queryParams = new URLSearchParams(window.location.search);
      const thankyoupagetype = queryParams.get('type');
      // alert(thankyoupagetype);

      const [htmlContent, setHtmlContent] = useState(<><h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">ThankYou</h2><h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">You have sucsessfully subscribed!</h2>
      <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">We sent an email on your email please activate your account</p></>);

useEffect(() => {   
   if(thankyoupagetype == 'request_accepted'){
      setHtmlContent(<><h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">ThankYou</h2><p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">Now you are a group member of group</p></>)
   }
}, [])

      //request_accepted

        return(
            <>
            <section className="section-box pt-50 pb-50">
                  <div className="post-loop-grid">
                     <div className="container">
                        <div className="text-center ">
                           <div className="text-center thankyouimage"><img src="front/assets/imgs/thankypim.png" /></div>
                           {/* <h6 className="f-18 color-text-mutted text-uppercase">Thank You</h6> */}
                           
                            {htmlContent}
                        </div>
                     </div>
                  </div>
               </section>
            </>
        );
}
export default ThankYou;